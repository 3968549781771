import React from 'react';
import styled, { css } from 'styled-components';
import { func, string, arrayOf, number, bool } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { AsYouType } from 'libphonenumber-js/max';

import { VerificationCodeInput } from '@atoms/Input';
import { Button, Typography, Flex } from '@mixins';
import { Auth } from '@templates';
import { theme } from '@styles';
import { routes } from '@constants';

const VerificationCode = ({
  isLoading,
  error,
  onCodeSubmit,
  onVerificationCodeChange,
  verificationCode,
  codeAddress,
  onResendClick,
}) => {
  const history = useHistory();
  const text = `We just send a 4-digit code to\n${
    codeAddress.includes('@') ? codeAddress : new AsYouType().input(codeAddress)
  }`;

  const handleBackClick = () => {
    history.push(routes.login);
  };

  return (
    <Auth title="Enter Your Code" text={text} isLoading={isLoading} hasBackButton onBackClick={handleBackClick}>
      <Flex flexDirection="column">
        <VerificationCodeInput
          quantityOfDigits={4}
          verificationCode={verificationCode}
          onVerificationCodeChange={onVerificationCodeChange}
          hasError={!!error}
          onCodeSubmit={onCodeSubmit}
        />
        <Error
          mt="15px"
          mb="65px"
          alignSelf="start"
          hasError={error}
          fontSize={['12px', '14px']}
          variant="proximaNova-400"
          color={theme.color.red}>
          {error}
        </Error>
      </Flex>
      <Flex mb={10} alignItems="center">
        <Typography mr="3px" color={theme.color.gray[200]} fontSize="14px" variant="proximaNova-400">
          Haven&apos;t received the code?
        </Typography>
        <Button onClick={onResendClick} fontSize="14px" color={theme.color.darkGreen}>
          Resend
        </Button>
      </Flex>
    </Auth>
  );
};

const Error = styled(Typography)`
  ${({ hasError }) => css`
    visibility: ${hasError ? 'visible' : 'hidden'};
    height: 8px;
  `}
`;

VerificationCode.propTypes = {
  isLoading: bool.isRequired,
  error: string.isRequired,
  onCodeSubmit: func.isRequired,
  onVerificationCodeChange: func.isRequired,
  verificationCode: arrayOf(number).isRequired,
  codeAddress: string.isRequired,
  onResendClick: func.isRequired,
};

export default VerificationCode;
