import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BookedRoom as View } from '@views';
import { getBookingById, cancelBooking, resetPhases, getCompanyAvailableCreditsById } from '@store/booking/duck';
import { dayjs } from '@utils';
import { withNetwork } from '@hocs';
import { LOADING, SUCCESS, FAILURE } from '@constants/requestPhase';
import { trackEvent } from '@utils/mixpanel';

const BookedRoom = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isBookingProcessing, setIsBookingProcessing] = useState(true);

  const {
    bookingById: {
      bookedForCompany,
      toDateTime,
      fromDateTime,
      meetingRoom,
      title,
      credits,
      location,
      guests,
      user,
      company,
      money,
      paymentType,
      refundTo,
    },
    getBookingByIdPhase,
    cancelBookingPhase,
    getCompanyAvailableCreditsByIdPhase,
  } = useSelector(store => store.bookingStore);

  const { user: currentUser } = useSelector(store => store.userStore);

  const handleCancelBooking = useCallback(() => {
    dispatch(cancelBooking(id));
  }, []);

  useEffect(() => {
    if (company?.id) {
      dispatch(getCompanyAvailableCreditsById(company.id));
    }
  }, [company]);

  useEffect(() => {
    dispatch(getBookingById(id));

    trackEvent('Page Viewed', {
      Page: 'Booked Room',
    });
  }, []);

  useEffect(() => {
    if (
      getBookingByIdPhase === FAILURE ||
      (currentUser.id && getBookingByIdPhase === SUCCESS && currentUser?.id !== user?.id)
    ) {
      setIsBookingProcessing(false);
      dispatch(resetPhases());
      history.goBack();
    }
    if (getBookingByIdPhase === SUCCESS) {
      setIsBookingProcessing(false);

      const pageTitle = `Mindspace: Room Booked ${meetingRoom?.roomNumber}`;
      document.title = pageTitle;

      window.gtag('event', 'page_view', {
        page_title: pageTitle,
      });
    }
  }, [getBookingByIdPhase]);

  return (
    <View
      bookedForCompany={bookedForCompany}
      refundTo={refundTo}
      creditPrices={meetingRoom?.prices}
      isEventSpace={meetingRoom?.roomType === 'event_space'}
      isLoading={
        isBookingProcessing || cancelBookingPhase === LOADING || getCompanyAvailableCreditsByIdPhase === LOADING
      }
      id={id}
      onCancelBooking={handleCancelBooking}
      endDate={dayjs(toDateTime)}
      startDate={dayjs(fromDateTime)}
      number={meetingRoom?.roomNumber}
      seats={meetingRoom?.workstationCount}
      floor={meetingRoom?.floor}
      creditsPrice={meetingRoom?.creditsPerHour}
      currencyPrice={meetingRoom?.pricePerHour}
      moneySpent={money}
      paymentType={paymentType}
      currency={meetingRoom?.location.currency}
      features={meetingRoom?.features}
      title={title}
      guests={guests?.length}
      roomPhotos={meetingRoom?.gallery}
      creditsSpent={credits}
      address={location?.address}
      companyName={currentUser.company?.name}
      isActivate={meetingRoom?.location?.isWhiteLabeled}
    />
  );
};

export default withNetwork(BookedRoom);
