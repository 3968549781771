import React, { useRef } from 'react';
import { string, shape, func, bool } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Icon } from '@atoms';
import { AddToCalendar } from '@molecules';
import { Flex, Typography } from '@mixins';
import { useOutsideClick } from '@hooks';
import { routes } from '@constants';
import { theme } from '@styles';

import { ReactComponent as InviteGuests } from '@assets/svgs/InviteGuests.svg';
import { ReactComponent as Edit } from '@assets/svgs/Edit.svg';
import { ReactComponent as Close } from '@assets/svgs/Close.svg';

import { HoverBlock } from './styles';

const BookedRoomButtonsPanel = ({
  id,
  title,
  startDate,
  endDate,
  address,
  onInviteGuests,
  isModifyingAvailable,
  onCancelBooking,
}) => {
  const buttonRef = useRef();
  const dropdownRef = useRef();
  const history = useHistory();

  const { isOpen, setIsOpen } = useOutsideClick(false, [buttonRef, dropdownRef]);

  const handleBookingEdit = () => {
    history.push(`${routes.bookedRooms}/${id}/edit`);
  };

  const handleDotsClick = () => {
    setIsOpen(prevValue => !prevValue);
  };

  return (
    <Flex position="relative" width="100%" justifyContent="space-between" alignItems="center">
      <AddToCalendar title={title} address={address} startDate={startDate} endDate={endDate} />
      <Flex onClick={onInviteGuests} pointer position="relative" justifyContent="center" alignItems="center">
        <Icon height={12} mr="5px" fillWholePath color={theme.color.darkGreen} SVG={InviteGuests} />
        <Typography color={theme.color.darkGreen} variant="proximaNova-400" fontSize={[10, 12, 14, 16]}>
          Invite Guests
        </Typography>
      </Flex>
      <Typography onClick={handleDotsClick} pointer ref={buttonRef} color={theme.color.darkGreen} fontSize={25}>
        ···
      </Typography>
      {isOpen && (
        <Flex
          ref={dropdownRef}
          width={[150, 180, 215]}
          backgroundColor={theme.color.white}
          border="1px solid #D9D9D9"
          boxShadow="0px 4px 4px rgba(220, 220, 220, 0.25)"
          py="8px"
          position="absolute"
          top={35}
          right={0}
          flexDirection="column">
          {isModifyingAvailable && (
            <HoverBlock onClick={handleBookingEdit} pointer px={[10, 13, 16]} py={['8px', 10]} alignItems="center">
              <Icon mr={[12, 20]} height={12} width={12} color={theme.color.black} SVG={Edit} />
              <Typography fontSize={[10, 13, 16]}>Edit Booking</Typography>
            </HoverBlock>
          )}
          <HoverBlock onClick={onCancelBooking} pointer px={[10, 13, 16]} py={['8px', 10]} alignItems="center">
            <Icon mr={[12, 20]} height={12} width={12} SVG={Close} />
            <Typography fontSize={[10, 13, 16]}>Cancel Booking</Typography>
          </HoverBlock>
        </Flex>
      )}
    </Flex>
  );
};

BookedRoomButtonsPanel.defaultProps = {
  title: '',
  address: '',
  startDate: new Date(),
  endDate: new Date(),
};

BookedRoomButtonsPanel.propTypes = {
  onCancelBooking: func.isRequired,
  isModifyingAvailable: bool.isRequired,
  id: string.isRequired,
  address: string,
  title: string,
  endDate: shape({}),
  startDate: shape({}),
  onInviteGuests: func.isRequired,
};

export default BookedRoomButtonsPanel;
