import { init as sentryInit, withScope, captureMessage } from '@sentry/react';
import { SENTRY_DSN, ENV } from '@constants/common';
import {Integrations} from "@sentry/tracing";

const URL = window.location.host;

const tracesSampleRateMap = {
  dev: 0.5,
  stage: 0.5,
  prod: 0.1,
};

const DEFAULT_TRACING_RATE = 0.1;

export const init = () => {
  if (!URL.includes("localhost")) {
    sentryInit({
      dsn: SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      environment: ENV,
      tracesSampleRate: tracesSampleRateMap[ENV] || DEFAULT_TRACING_RATE,
      tracePropagationTargets: [
        /^http:\/\/localhost:3000\/api/,
        /^https:\/\/api.*\..*mindspace\.me.*/,
      ],
    });
  }
};

export const reportError = (error, tagKey, tagValue, extraInfo) => {
  withScope(scope => {
    if (tagKey && tagValue) {
      scope.setTag(tagKey, tagValue);
    }
    if (extraInfo) {
      scope.setExtras(extraInfo);
    }
    scope.setLevel('error');

    captureMessage(error);
  });
};
