import React, { useState, useMemo } from 'react';
import { arrayOf, string, number, exact, func, shape, bool } from 'prop-types';

import { Icon, Timeline } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { getSuffixByNumericValue, getCloudinaryImageLinkBySize, getCurrency } from '@helpers/common';
import { theme } from '@styles';

import VideoConferencing from '@assets/svgs/VideoConferencing.svg';
import { ReactComponent as Credits } from '@assets/svgs/Credits.svg';
import { ReactComponent as Seats } from '@assets/svgs/Seats.svg';
import RoomImage from '@assets/images/RoomImage.png';
import { useSelector } from 'react-redux';
import PricingDetails from '../PricingDetails';

const IMAGE_WIDTH = 269;

const RoomCard = ({
  creditPrices,
  maxBookingHours,
  minBookingHours,
  isEventSpace,
  id,
  imageUrl,
  number: roomNumber,
  floor,
  seats,
  creditsPrice,
  currencyPrice,
  availableTimes,
  features,
  onRoomClick,
  timelineScrollTo,
  bookings,
  timeList,
}) => {
  const { user } = useSelector(store => store.userStore);
  const { currentLocation } = useSelector(store => store.locationStore);

  const [startTime, setStartTime] = useState(-1);
  const [endTime, setEndTime] = useState(-1);

  const roomImage = getCloudinaryImageLinkBySize(imageUrl, IMAGE_WIDTH);

  const isSelectedTimelineAvailable = timeList
    .slice(startTime, endTime)
    .every(timeline => availableTimes.includes(timeline));

  const handleTimeClick = time => () => {
    if (time !== startTime) {
      const hours = isEventSpace ? minBookingHours : 1;
      const isPrevTimelineAvailable = availableTimes.includes(timeList[time - hours]);

      const isNextTimelineAvailable = availableTimes.includes(timeList[time + hours]);

      if (isPrevTimelineAvailable) {
        setStartTime(time - hours);
        setEndTime(time + hours);
      }
      if (isNextTimelineAvailable) {
        setStartTime(time);
        setEndTime(time + hours * 2);
      }
      if (
        !isPrevTimelineAvailable &&
        !isNextTimelineAvailable &&
        !user.isActivate &&
        (!isEventSpace || minBookingHours < 1)
      ) {
        setStartTime(time);
        setEndTime(time + 1);
      }
    }
  };

  const handleTimeChange = time => {
    const timeDifference = time[1] - time[0];
    if (
      isEventSpace
        ? timeDifference >= minBookingHours * 2 && timeDifference <= maxBookingHours * 2
        : timeDifference > (user.isActivate ? 1 : 0)
    ) {
      if (timeList.slice(time[0], time[1]).every(timeline => availableTimes.includes(timeline))) {
        setStartTime(time[0]);
        setEndTime(time[1]);
      }
    }
  };

  const featuresBlock = useMemo(
    () =>
      features.map(({ id: featureId, iconUrl, displayName }) => (
        <Flex mb={10} mr={['6px', '8px', 10]} alignItems="center" key={featureId}>
          <svg width="20" height="20">
            <image xlinkHref={iconUrl || VideoConferencing} width="20" height="20" />
          </svg>
          <Typography
            ml={['4px', '6px', '8px']}
            variant="proximaNova-400"
            fontSize={[10, 10, 12, 14]}
            color={theme.color.gray[300]}>
            {displayName}
          </Typography>
        </Flex>
      )),
    [features],
  );

  return (
    <Flex
      border="1px solid #EFECE6"
      boxShadow="0px 8px 30px rgba(80, 85, 136, 0.06)"
      borderRadius="2px"
      mb={[10, 10, 15, 20]}
      p={[10, 10, 15, 20]}
      width="100%">
      <Flex
        background={`url(${roomImage || RoomImage}) center no-repeat`}
        backgroundSize="cover"
        mr={[10, 15, 20, 25]}
        width="23%"
        maxHeight={[180, 180, 180, 195]}
      />
      <Flex width="77%" flexDirection="column">
        <Flex width="100%" mb={[10, 15, 20]} alignItems="center" justifyContent="space-between">
          <Flex mr={10} flexDirection="column">
            <Flex mb={10} alignItems="center">
              <Typography mr={['8px', '8px', 10, 12]} variant="proximaNova-600" fontSize={[14, 16, 18, 20]}>
                {`${roomNumber} · ${getSuffixByNumericValue(floor)} floor`}
              </Typography>
              <PricingDetails
                locationTimeFormat={currentLocation.timeFormat}
                baseCreditPrice={creditPrices?.base?.creditsPerHour}
                peakTimeCreditPrice={creditPrices?.peakPrice?.creditsPerHour}
                peakTimeDays={creditPrices?.peakPrice?.timeFrames.daysOfWeek}
                peakTimeIntervals={creditPrices?.peakPrice?.timeFrames.times}
                isClickable={!user.isActivate && creditPrices?.peakPrice}>
                {!user.isActivate && creditPrices?.peakPrice && (
                  <Typography
                    mr="5px"
                    variant="proximaNova-400"
                    fontSize={[11, 12, 13, 14]}
                    color={theme.color.gray[300]}>
                    from
                  </Typography>
                )}
                <Icon mr="5px" SVG={Credits} width={[10, 13, 16]} heigh={[10, 13, 16]} />
                <Typography variant="proximaNova-400" fontSize={[11, 12, 13, 14]} color={theme.color.gray[300]}>
                  {`${creditsPrice} / hr${
                    user.isActivate ? ` or ${getCurrency(currentLocation.currency, currencyPrice)} / hr` : ''
                  }`}
                </Typography>
              </PricingDetails>
            </Flex>
            <Flex flexWrap="wrap" alignItems="center">
              <Icon mb={10} SVG={Seats} width={[10, 14, 18]} height={[8, 10, 12]} mr={['6px', '8px', 10]} />
              <Typography
                {...(!!features.length && { borderRight: `1px solid ${theme.color.black}` })}
                pr={['6px', '6px', '8px', 10]}
                variant="proximaNova-400"
                fontSize={[10, 12, 14, 16]}
                mb={10}
                color={theme.color.gray[300]}
                mr={['6px', '8px', 10]}>
                {`${seats} ${seats === 1 ? 'seat' : 'seats'}`}
              </Typography>
              {featuresBlock}
            </Flex>
          </Flex>
          <Button
            py={['6px', '8px', 10, 12]}
            px={[20, 25, 30, 35]}
            variant="primary"
            fontSize={[10, 10, 13, 16]}
            onClick={onRoomClick(id, startTime, endTime)}
            disabled={!isSelectedTimelineAvailable || (endTime === -1 && startTime === -1)}>
            Book this room
          </Button>
        </Flex>
        <Timeline
          userRole={user?.role}
          bookings={bookings}
          timeZone={currentLocation.timeZone}
          timeFormat={currentLocation.timeFormat}
          timeList={timeList}
          scrollTo={timelineScrollTo}
          onTimeClick={handleTimeClick}
          startTime={startTime}
          endTime={endTime}
          availableTimes={availableTimes}
          onTimeChange={handleTimeChange}
        />
      </Flex>
    </Flex>
  );
};

RoomCard.defaultProps = {
  creditPrices: {},
  maxBookingHours: 1,
  minBookingHours: 3,
  bookings: [],
  id: 0,
  imageUrl: '',
  number: '',
  floor: 1,
  seats: 1,
  creditsPrice: 0,
  currencyPrice: 0,
  timeList: [],
  availableTimes: [],
  features: [],
};

RoomCard.propTypes = {
  creditPrices: exact({
    base: exact({
      creditsPerHour: number,
    }),
    peakPrice: exact({
      creditsPerHour: number,
      timeFrames: exact({
        daysOfWeek: number,
        times: arrayOf(
          exact({
            from: string,
            to: string,
          }),
        ),
      }),
    }),
  }),
  maxBookingHours: number,
  minBookingHours: number,
  isEventSpace: bool.isRequired,
  bookings: arrayOf(
    exact({
      fromDateTime: string,
      toDateTime: string,
      company: string,
    }),
  ),
  timelineScrollTo: number.isRequired,
  id: number,
  onRoomClick: func.isRequired,
  imageUrl: string,
  number: string,
  floor: number,
  seats: number,
  creditsPrice: number,
  currencyPrice: number,
  timeList: arrayOf(shape({})),
  availableTimes: arrayOf(shape({})),
  features: arrayOf(
    exact({
      id: number,
      displayName: string,
      iconUrl: string,
      key: string,
    }),
  ),
};

export default RoomCard;
