import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routes } from '@constants';
import { Flex, Typography } from '@mixins';
import { getSuffixByNumericValue, getCloudinaryImageLinkBySize } from '@helpers/common';
import { dayjs } from '@utils';
import { formatTime } from '@helpers/time';
import { theme } from '@styles';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import RoomImage from '@assets/images/RoomImage.png';

import { Description } from '../../organisms/NeighborsList/styles';
import { RoomContainer, StyledDropdown, ScrollableContainer } from './styles';

const BookedRooms = () => {
  const history = useHistory();

  const [isExpanded, setIsExpanded] = useState(false);

  const { bookedRooms } = useSelector(store => store.bookingStore);

  const { currentLocation } = useSelector(store => store.locationStore);

  const handleDropdownClick = () => {
    setIsExpanded(prevValue => !prevValue);
  };

  const handleRoomClick = id => () => {
    history.push(`${routes.bookedRooms}/${id}`);
  };

  const roomsBlock = useMemo(
    () =>
      bookedRooms.map(
        ({
          id,
          meetingRoom: {
            location: { timeFormat, timeZone },
            roomNumber,
            floor,
            image,
          },
          fromDateTime,
          toDateTime,
          title,
          bookedForCompany,
        }) => (
          <RoomContainer
            onClick={handleRoomClick(id)}
            pointer
            width={[200, theme.sizes.bookedRooms]}
            key={id}
            flexDirection="column"
            mb={[20, 30]}
            borderRadius="2px"
            border={`1px solid ${theme.color.gray[100]}`}>
            <Flex
              justifyContent="start"
              alignItems="start"
              width="100%"
              pl={[10, 12]}
              pt={[11, 15]}
              height={[120, 158]}
              background={`url(${getCloudinaryImageLinkBySize(image, theme.sizes.bookedRooms) || RoomImage}) center`}
              backgroundSize="cover">
              {dayjs(fromDateTime).tz(timeZone).date() === dayjs().tz(timeZone).date() && (
                <Typography
                  px="8px"
                  py="4px"
                  variant="proximaNova-600"
                  fontSize={[11, 13]}
                  backgroundColor={theme.color.white}
                  borderRadius="2px">
                  Today
                </Typography>
              )}
            </Flex>
            <Flex alignItems="start" flexDirection="column" p={[16, 20]}>
              <Typography variant="proximaNova-600" fontSize={[14, 16]}>
                {`${roomNumber} · ${getSuffixByNumericValue(floor)} floor`}
              </Typography>
              <Typography color={theme.color.gray[200]} mt="5px" variant="proximaNova-400" fontSize={[12, 14]}>
                {`${dayjs(fromDateTime).tz(timeZone).format('MMM')} ${dayjs(fromDateTime).tz(timeZone).date()}, 
          ${`${formatTime(
            fromDateTime,
            timeFormat,
            '',
            timeZone,
          )}-${formatTime(toDateTime, timeFormat, '', timeZone)}`}`}
              </Typography>
              {title && (
                <Description color={theme.color.gray[200]} mt="5px" variant="proximaNova-400" fontSize={[12, 14]}>
                  {title}
                </Description>
              )}
              {bookedForCompany && (
                <Description color={theme.color.gray[200]} mt="5px" variant="proximaNova-400" fontSize={[12, 14]}>
                  {`Booked For ${bookedForCompany}`}
                </Description>
              )}
            </Flex>
          </RoomContainer>
        ),
      ),
    [bookedRooms, currentLocation.timeFormat, currentLocation.timeZone],
  );

  return (
    <Flex
      backgroundColor={theme.color.white}
      width={[250, 350]}
      position="fixed"
      zIndex={8}
      right={0}
      top={[250, 270, 370, 290]}
      boxShadow="0px 4px 30px rgba(0, 0, 0, 0.15)"
      flexDirection="column">
      <Flex onClick={handleDropdownClick} pointer p={[20, 28]} justifyContent="start">
        <Flex
          borderRadius="50%"
          mr={[10, 15]}
          width={[24, 32]}
          height={[24, 32]}
          backgroundColor={theme.color.pink}
          justifyContent="center"
          alignItems="center">
          <StyledDropdown pt="1px" isExpanded={isExpanded} color={theme.color.black} SVG={Dropdown} />
        </Flex>
        <Typography variant="proximaNova-600" fontSize={[16, 20]}>
          You have rooms booked
        </Typography>
      </Flex>
      {isExpanded && (
        <ScrollableContainer
          height={[235, 295]}
          alignSelf="start"
          pl={['12px', '15px']}
          ml={['12px', '15px']}
          pr={[24, 30]}>
          {roomsBlock}
        </ScrollableContainer>
      )}
    </Flex>
  );
};

export default BookedRooms;
