import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { RoomDetails as View } from '@views';
import { routes } from '@constants';
import { LOADING, SUCCESS, FAILURE } from '@constants/requestPhase';
import { COMMUNITY_MANAGER_ROLE, ADMIN_ROLE, BOOKING_DEFAULT_TITLE, EMPLOYEE_CM_ROLE } from '@constants/common';
import { withNetwork } from '@hocs';
import { getTimeList, getAvailableTimelines, formatTime } from '@helpers/time';
import { useDebounce } from '@hooks';
import { dayjs } from '@utils';
import { getBookingById, editBooking, resetPhases } from '@store/booking/duck';
import { getBookingCost, getMeetingRoomBookingsById } from '@store/room/duck';
import { trackEvent } from '@utils/mixpanel';

const getEndTime = (stateEndTime, bookingEndTime) => {
  switch (true) {
    case !!stateEndTime:
      return stateEndTime;
    case bookingEndTime === 0:
      return 48;
    default:
      return bookingEndTime;
  }
};

const BookedRoomEdit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const { user: currentUser } = useSelector(store => store.userStore);

  const { currentLocation } = useSelector(store => store.locationStore);

  const {
    bookingById: {
      id: bookingId,
      credits,
      toDateTime,
      fromDateTime,
      meetingRoom,
      user,
      title: bookingTitle,
      company,
      paymentType,
    },
    getBookingByIdPhase,
    editBookingPhase,
    editBookingError,
  } = useSelector(store => store.bookingStore);

  const { getMeetingRoomBookingsByIdPhase, getBookingCostPhase, roomById } = useSelector(store => store.roomStore);

  const bookings = useMemo(
    () => roomById?.bookings.filter(booking => booking.fromDateTime !== fromDateTime) || [],
    [roomById?.bookings, fromDateTime],
  );

  const [isBookingProcessing, setIsBookingProcessing] = useState(false);
  const [date, setDate] = useState(
    location.state?.date
      ? dayjs()
          .year(location.state.year)
          .month(location.state.month)
          .date(location.state.date)
          .add(dayjs().$u ? new Date().getTimezoneOffset() : 0, 'minute')
      : dayjs(fromDateTime ?? 0).add(dayjs().$u ? new Date().getTimezoneOffset() : 0, 'minute'),
  );

  const timeList = useMemo(
    () => getTimeList(date, meetingRoom?.location.timeZone),
    [date.$D, date.$M, date.$y, currentLocation.timeZone],
  );

  const startTimeIndex = timeList.findIndex(time => formatTime(time, '24h') === formatTime(fromDateTime, '24h'));

  const endTimeIndex = timeList.findIndex(time => formatTime(time, '24h') === formatTime(toDateTime, '24h'));

  const maxBookingHours = meetingRoom?.maxBookingMinutes ? meetingRoom.maxBookingMinutes / 60 : 24;
  const minBookingHours = meetingRoom?.minBookingMinutes ? meetingRoom.minBookingMinutes / 60 : 0.5;

  const [error, setError] = useState({});
  const [startTime, setStartTime] = useState(location.state?.startTime ?? startTimeIndex);
  const [endTime, setEndTime] = useState(getEndTime(location.state?.endTime, endTimeIndex));
  const [title, setTitle] = useState(bookingTitle ?? '');

  const isEventSpace = roomById?.roomType === 'event_space';

  const [isPayByCardOpen, setIsPayByCardOpen] = useState(false);

  const isToday = date.$d.toString().substring(0, 15) === dayjs().$d.toString().substring(0, 15);

  const timelinesList = isToday
    ? timeList.filter(timeline => {
        const time = timeline.tz(meetingRoom?.location.timeZone).$d;

        const now = dayjs()
          .minute(dayjs().tz(meetingRoom?.location.timeZone).minute() - 10)
          .second(0)
          .millisecond(0)
          .tz(meetingRoom?.location.timeZone).$d;

        return time >= now;
      })
    : timeList;

  const availableTimes = getAvailableTimelines(timelinesList, bookings);

  const debouncedTime = useDebounce([startTime, endTime], 300);

  const handleTitleChange = useCallback(e => {
    setTitle(e.target.value);
  }, []);

  const handleEditBooking = useCallback(() => {
    setIsBookingProcessing(true);

    dispatch(
      editBooking(id, {
        title: title || BOOKING_DEFAULT_TITLE,
        fromDateTime: timeList[startTime].toISOString(),
        toDateTime: timeList[endTime] ? timeList[endTime].toISOString() : timeList[0].add(1, 'day').toISOString(),
      }),
    );
  }, [timeList, title, startTime, endTime, id]);

  const handleTimelineClick = time => () => {
    if (time !== startTime) {
      const hours = isEventSpace ? minBookingHours : 1;
      const isPrevTimelineAvailable = availableTimes.includes(timeList[time - hours]);

      const isNextTimelineAvailable = availableTimes.includes(timeList[time + hours]);

      if (isPrevTimelineAvailable) {
        setStartTime(time - hours);
        setEndTime(time + hours);
      }
      if (isNextTimelineAvailable) {
        setStartTime(time);
        setEndTime(time + hours * 2);
      }
      if (
        !isPrevTimelineAvailable &&
        !isNextTimelineAvailable &&
        !user.isActivate &&
        (!isEventSpace || minBookingHours < 1)
      ) {
        setStartTime(time);
        setEndTime(time + 1);
      }
    }
  };

  const handleTimelineChange = useCallback(
    time => {
      const timeDifference = time[1] - time[0];

      if (
        isEventSpace
          ? timeDifference >= minBookingHours * 2 && timeDifference <= maxBookingHours * 2
          : timeDifference > (user.isActivate ? 1 : 0)
      ) {
        if (timeList.slice(time[0], time[1]).every(timeline => availableTimes.includes(timeline))) {
          setStartTime(time[0]);
          setEndTime(time[1]);
        }
      }
    },
    [timeList, availableTimes],
  );

  const handleBackClick = useCallback(() => {
    history.push(`${routes.bookedRooms}/${id}`);
  }, [id]);

  const handleUpdateRoom = useCallback(() => {
    history.push(`${routes.bookedRooms}/${id}`);
  }, [id]);

  const handleDateChange = useCallback(
    newDate => {
      const offset = dayjs(newDate).$offset === undefined ? 0 : dayjs(newDate).$offset;

      setDate(
        dayjs(newDate)
          .add(-(offset + new Date(newDate).getTimezoneOffset()), 'minutes')
          .add(dayjs(newDate).$u ? -new Date(newDate).getTimezoneOffset() : 0, 'minute'),
      );
    },
    [dayjs().$u, dayjs().$offset, new Date().getTimezoneOffset()],
  );

  const handleEndTimeChange = useCallback(newEndTime => {
    setEndTime(newEndTime);
  }, []);

  const handleStartTimeChange = useCallback(newStartTime => {
    setStartTime(newStartTime);
  }, []);

  const handleBuyCreditsClick = useCallback(() => {
    history.push({
      pathname: routes.creditsPackages,
      state: {
        from: window.location.pathname,
        data: {
          date: date.$D,
          month: date.$M,
          year: date.$y,
          startTime,
          endTime,
        },
      },
    });
  }, [date, startTime, endTime]);

  const resetValues = useCallback(() => {
    setDate(dayjs(fromDateTime));
    setStartTime(location.state?.startTime ?? startTimeIndex);
    setEndTime(getEndTime(location.state?.endTime, endTimeIndex));
    setTitle(bookingTitle);
  }, [fromDateTime, company, location.state, bookingTitle]);

  const handlePayByCardChangeState = useCallback(() => {
    setIsPayByCardOpen(prevState => !prevState);
  }, []);

  useEffect(() => {
    trackEvent('Page Viewed', {
      Page: 'Booked Room Editing',
    });

    if (bookingId !== parseInt(id, 10)) {
      dispatch(getBookingById(id));
    }
  }, [id]);

  useEffect(() => {
    if (
      getBookingByIdPhase === FAILURE ||
      (currentUser.id &&
        getBookingByIdPhase === SUCCESS &&
        (currentUser?.id !== user?.id || dayjs(fromDateTime) < dayjs().add(1, 'hour')))
    ) {
      dispatch(resetPhases());
      history.goBack();
    }
  }, [getBookingByIdPhase]);

  useEffect(() => {
    if (getBookingByIdPhase === SUCCESS) {
      const pageTitle = `Mindspace: Room Booked ${meetingRoom?.roomNumber}`;
      document.title = pageTitle;

      window.gtag('event', 'page_view', {
        page_title: pageTitle,
      });

      dispatch(resetPhases());
      resetValues();
    }
    if (editBookingPhase === SUCCESS) {
      dispatch(resetPhases());
      history.push(`${routes.bookedRooms}/${id}`);
      setIsBookingProcessing(false);
    }
    if (editBookingPhase === FAILURE) {
      if (paymentType === 'money') {
        handlePayByCardChangeState();
      } else {
        setError({
          status: editBookingError.data.error.status,
          message: editBookingError.data.error.message,
        });
      }
      setIsBookingProcessing(false);
      dispatch(resetPhases());
    }
  }, [getBookingByIdPhase, editBookingPhase, editBookingPhase]);

  useEffect(() => {
    if (meetingRoom?.id && startTime >= 0) {
      dispatch(
        getBookingCost({
          from: timeList[startTime]?.toISOString(),
          to: timeList[endTime] ? timeList[endTime]?.toISOString() : timeList[0]?.add(1, 'day').toISOString(),
          meetingRoomId: meetingRoom?.id,
          ...(company?.id && { companyId: company.id }),
        }),
      );
    }
  }, [debouncedTime, meetingRoom?.id, startTime, date]);

  useEffect(() => {
    if (meetingRoom?.id) {
      dispatch(getMeetingRoomBookingsById(meetingRoom?.id, timeList[0].toISOString()));
    }
  }, [date, meetingRoom?.id]);

  const isAdmin =
    currentUser.role === COMMUNITY_MANAGER_ROLE ||
    currentUser.role === EMPLOYEE_CM_ROLE ||
    currentUser.role === ADMIN_ROLE;

  return (
    <View
      bookings={bookings}
      creditPrices={meetingRoom?.prices}
      maxBookingHours={maxBookingHours}
      minBookingHours={minBookingHours}
      isEventSpace={isEventSpace}
      onBuyCreditsClick={handleBuyCreditsClick}
      isAdmin={isAdmin}
      selectedCompany={company}
      error={error}
      isEditing
      onEditBooking={handleEditBooking}
      onBackClick={handleBackClick}
      isCostLoading={getBookingCostPhase === LOADING}
      areBookingsLoading={getMeetingRoomBookingsByIdPhase === LOADING}
      onSubmit={handleUpdateRoom}
      times={timeList}
      onDateChange={handleDateChange}
      onTimelineClick={handleTimelineClick}
      onTimelineChange={handleTimelineChange}
      onEndTimeChange={handleEndTimeChange}
      onStartTimeChange={handleStartTimeChange}
      date={date}
      endTime={endTime}
      startTime={startTime}
      isLoading={getBookingByIdPhase === LOADING || isBookingProcessing}
      roomNumber={meetingRoom?.roomNumber}
      seats={meetingRoom?.workstationCount}
      floor={meetingRoom?.floor}
      creditsPrice={meetingRoom?.creditsPerHour}
      currencyPrice={meetingRoom?.pricePerHour}
      features={meetingRoom?.features}
      availableTimes={availableTimes}
      title={title}
      onTitleChange={handleTitleChange}
      roomPhotos={meetingRoom?.gallery}
      companyName={currentUser.company?.name}
      credits={credits}
      isPayByCardOpen={isPayByCardOpen}
      onPayByCardChangeState={handlePayByCardChangeState}
    />
  );
};

export default withNetwork(BookedRoomEdit);
