import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Rooms as View } from '@views';
import { routes } from '@constants';
import { withNetwork } from '@hocs';
import { dayjs } from '@utils';
import { getTimeList } from '@helpers/time';
import { SUCCESS, LOADING, FAILURE } from '@constants/requestPhase';
import { getCompanyManagers } from '@store/company/duck';
import { getMeetingRooms, getMeetingRoomsFilters, resetPhases as resetRoomsPhases } from '@store/room/duck';
import { trackEvent } from '@utils/mixpanel';

const Rooms = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { currentLocation } = useSelector(store => store.locationStore);

  const { user } = useSelector(store => store.userStore);

  const { getCompanyManagersPhase } = useSelector(store => store.companyStore);

  const { getMeetingRoomsPhase, getMeetingRoomsFiltersPhase, getMeetingRoomsFiltersError, getMeetingRoomsError } =
    useSelector(store => store.roomStore);

  const [error, setError] = useState({});
  const [date, setDate] = useState(
    location.state?.filters
      ? dayjs()
          .date(JSON.parse(location.state.filters).date)
          .month(JSON.parse(location.state.filters).month)
          .year(JSON.parse(location.state.filters).year)
          .add(dayjs().$u ? new Date().getTimezoneOffset() : 0, 'minute')
      : dayjs().add(dayjs().$u ? new Date().getTimezoneOffset() : 0, 'minute'),
  );
  const [startTime, setStartTime] = useState(
    location.state?.filters && JSON.parse(location.state.filters).startTime
      ? dayjs(JSON.parse(location.state.filters).startTime)
      : null,
  );
  const [endTime, setEndTime] = useState(
    location.state?.filters && JSON.parse(location.state.filters).endTime
      ? dayjs(JSON.parse(location.state.filters).endTime)
      : null,
  );
  const [floor, setFloor] = useState(location.state?.filters ? JSON.parse(location.state.filters).floor : null);
  const [seats, setSeats] = useState(location.state?.filters ? JSON.parse(location.state.filters).seats : null);
  const [type, setType] = useState(location.state?.filters ? JSON.parse(location.state.filters).type : null);
  const [selectedCustomFilters, setSelectedCustomFilters] = useState(
    location.state?.filters ? JSON.parse(location.state.filters).selectedCustomFilters : [],
  );

  const isNotAbleToBookRoom = !user.isAbleToBookMeetingRoom && !!user.id;

  const timeList = useMemo(() => getTimeList(date), [dayjs().$u, date.$D, date.$M, date.$y, currentLocation.timeZone]);

  useEffect(() => {
    const pageTitle = 'Mindspace: Book a Room';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Rooms',
    });
  }, []);

  useEffect(() => {
    if (isNotAbleToBookRoom) {
      dispatch(getCompanyManagers(user.company.id));
    }
  }, [user.id]);

  useEffect(() => {
    if (currentLocation.id) {
      const index = timeList.indexOf(endTime);

      dispatch(
        getMeetingRooms({
          locationId: currentLocation.id,
          ...((floor || floor === 0) && { floor }),
          ...(type && { roomType: type === 'Event Space' ? 'event_space' : 'meeting_room' }),
          ...(seats && { workstationCount: Number(seats.replace('+', '')) }),
          ...(date && { date: timeList[0]?.toISOString() }),
          ...(startTime &&
            endTime && {
              timeFrom: startTime.toISOString(),
              timeTo: endTime.date(index ? date.$D : date.$D + 1).toISOString(),
            }),
          features: selectedCustomFilters,
        }),
      );
    }
  }, [currentLocation.id, date.$D, date.$M, date.$YY, startTime, endTime, floor, seats, selectedCustomFilters, type]);

  useEffect(() => {
    if (currentLocation.id) {
      dispatch(
        getMeetingRoomsFilters({
          locationId: currentLocation.id,
        }),
      );
    }
  }, [currentLocation.id]);

  useEffect(() => {
    if (getMeetingRoomsPhase === SUCCESS) {
      dispatch(resetRoomsPhases());
    }
    if (getMeetingRoomsPhase === FAILURE) {
      setError({
        status: getMeetingRoomsError.data.error?.status,
        message: getMeetingRoomsError.data.error?.message,
      });
      dispatch(resetRoomsPhases());
    }
    if (getMeetingRoomsFiltersPhase === FAILURE) {
      setError({
        status: getMeetingRoomsFiltersError.data.error?.status,
        message: getMeetingRoomsFiltersError.data.error?.message,
      });
      dispatch(resetRoomsPhases());
    }
  }, [getMeetingRoomsPhase, getMeetingRoomsFiltersPhase]);

  const handleDateChange = useCallback(
    newDate => {
      const offset = dayjs(newDate).$offset === undefined ? 0 : dayjs(newDate).$offset;

      setDate(
        dayjs(newDate)
          .add(-(offset + new Date(newDate).getTimezoneOffset()), 'minutes')
          .add(dayjs(newDate).$u ? -new Date(newDate).getTimezoneOffset() : 0, 'minute'),
      );
    },
    [dayjs().$u, dayjs().$offset, new Date().getTimezoneOffset()],
  );

  const handleEndTimeChange = useCallback(newEndTime => {
    setEndTime(newEndTime);
  }, []);

  const handleStartTimeChange = useCallback(newStartTime => {
    setStartTime(newStartTime);
  }, []);

  const handleTypeChange = useCallback(newType => {
    setType(newType);
  }, []);

  const handleFloorChange = useCallback(newFloor => {
    setFloor(newFloor);
  }, []);

  const handleSeatsChange = useCallback(newSeats => {
    setSeats(newSeats);
  }, []);

  const handleCustomFilterClick = id => {
    setSelectedCustomFilters(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(filter => filter !== id);
      }
      return [id, ...prevState];
    });
  };

  const handleRoomClick = (id, selectedStartTime, selectedEndTime) => () => {
    const startBookingTime = startTime
      ? dayjs(startTime).add(-(dayjs().$offset + new Date(selectedStartTime).getTimezoneOffset()), 'minutes').$d
      : null;
    const endBookingTime = endTime
      ? dayjs(endTime).add(-(dayjs().$offset + new Date(selectedEndTime).getTimezoneOffset()), 'minutes').$d
      : null;

    history.push({
      pathname: `${routes.rooms}/${id}`,
      state: {
        date: date.$D,
        month: date.$M,
        year: date.$y,
        filters: JSON.stringify({
          date: date.$D,
          month: date.$M,
          year: date.$y,
          startTime: startBookingTime,
          endTime: endBookingTime,
          floor,
          type,
          seats,
          selectedCustomFilters,
        }),
        startTime: selectedStartTime,
        endTime: selectedEndTime,
      },
    });
  };

  const isLoading =
    getMeetingRoomsFiltersPhase === LOADING ||
    getCompanyManagersPhase === LOADING ||
    (getMeetingRoomsFiltersPhase === SUCCESS && getMeetingRoomsPhase === LOADING);

  return (
    <View
      onTypeChange={handleTypeChange}
      type={type}
      isCompanyManagersModalOpen={isNotAbleToBookRoom}
      times={timeList}
      error={error}
      areRoomsLoading={getMeetingRoomsPhase === LOADING}
      onDateChange={handleDateChange}
      onEndTimeChange={handleEndTimeChange}
      onStartTimeChange={handleStartTimeChange}
      onSeatsChange={handleSeatsChange}
      onFloorChange={handleFloorChange}
      date={date}
      endTime={endTime}
      startTime={startTime}
      floor={floor}
      seats={seats}
      isLoading={isLoading}
      selectedCustomFilters={selectedCustomFilters}
      onCustomFilterClick={handleCustomFilterClick}
      onRoomClick={handleRoomClick}
    />
  );
};

export default withNetwork(Rooms);
