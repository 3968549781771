import React, { useEffect, useState } from 'react';
import { node, bool, string, exact, number, shape } from 'prop-types';
import { useSelector } from 'react-redux';

import { Loader, HeaderAnnouncement } from '@atoms';
import { Footer, ErrorModal } from '@molecules';
import { Header } from '@organisms';
import { LOADING } from '@constants/requestPhase';
import { HEADER_ANNOUNCEMENT_KEY, UNAUTHORIZED } from '@constants/common';
import { Flex } from '@mixins';
import { theme } from '@styles';
import { storage } from '@utils';

const Main = ({
  withPaddingX,
  children,
  isLoading,
  isWhiteLabelingOpen,
  error,
  afterHeaderContent,
  afterHeaderContentId,
  parsedHeaderAnnouncementSavedLocally,
}) => {
  const [errorObject, setErrorObject] = useState(error);
  const [headerAnnouncementOpen, setHeaderAnnouncementOpen] = useState(true);

  const isHeaderAnnouncementCurrentSessionOpen = JSON.parse(window.sessionStorage.getItem('isAnnouncementShown'));

  const { getCurrentUserPhase, refreshTokenPhase } = useSelector(store => store.userStore);

  const { currentLocation, getCurrentLocationPhase } = useSelector(store => store.locationStore);

  const isLoadingState =
    getCurrentUserPhase === LOADING || getCurrentLocationPhase === LOADING || refreshTokenPhase === LOADING;

  const handleErrorModalClose = () => {
    setErrorObject({});
  };

  const closeHeaderAnnouncement = () => {
    setHeaderAnnouncementOpen(false);
    window.sessionStorage.setItem('isAnnouncementShown', false);

    storage.setToLocalStorage(HEADER_ANNOUNCEMENT_KEY, {
      id: afterHeaderContentId || null,
      locationId: currentLocation?.id || null,
    });
  };

  useEffect(() => {
    setErrorObject(error);
  }, [error]);

  useEffect(() => {
    if (afterHeaderContentId === null) {
      storage.setToLocalStorage(HEADER_ANNOUNCEMENT_KEY, {
        id: null,
        locationId: parsedHeaderAnnouncementSavedLocally.locationId,
      });
    }
  }, [currentLocation?.id, parsedHeaderAnnouncementSavedLocally, afterHeaderContentId]);

  return (
    <>
      <Flex
        position="relative"
        flexDirection="column"
        pb={[162, 307, 260]}
        pt={[35, 46, 60]}
        alignItems="center"
        minHeight="100vh"
        opacity={isWhiteLabelingOpen ? 0 : 1}>
        <Header />
        {headerAnnouncementOpen && isHeaderAnnouncementCurrentSessionOpen !== false && afterHeaderContent && (
          <HeaderAnnouncement handleClose={closeHeaderAnnouncement}>{afterHeaderContent}</HeaderAnnouncement>
        )}
        <Flex
          width="100%"
          alignItems="center"
          flexGrow={1}
          pt={30}
          maxWidth={withPaddingX ? 1440 : '100%'}
          px={withPaddingX ? [20, 30, 40, 60] : 0}
          pb={80}
          flexDirection="column">
          {children}
        </Flex>
        <Footer />
      </Flex>
      {(isLoading || isLoadingState) && (
        <Loader position="fixed" background={theme.color.white} width={64} height={64} />
      )}
      {!!Object.keys(errorObject).length && (
        <ErrorModal
          error={errorObject}
          onModalClose={handleErrorModalClose}
          isOpen={!!Object.keys(errorObject).length && errorObject.status !== UNAUTHORIZED}
        />
      )}
    </>
  );
};

Main.defaultProps = {
  isWhiteLabelingOpen: false,
  isLoading: false,
  error: {},
  withPaddingX: true,
  afterHeaderContent: '',
  afterHeaderContentId: null,
  parsedHeaderAnnouncementSavedLocally: {},
};

Main.propTypes = {
  withPaddingX: bool,
  children: node.isRequired,
  error: exact({
    message: string,
    title: string,
    status: number,
  }),
  isLoading: bool,
  isWhiteLabelingOpen: bool,
  afterHeaderContent: node,
  afterHeaderContentId: number,
  parsedHeaderAnnouncementSavedLocally: shape({}),
};

export default Main;
