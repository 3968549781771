import React, { useState, useCallback } from 'react';
import { string, bool, exact, arrayOf, func, shape, number } from 'prop-types';

import { TextInput } from '@atoms/Input';
import { EditSocials, TextModal, SearchListModal, ConfirmationModal } from '@molecules';
import { Main } from '@templates';
import { theme } from '@styles';
import { Typography, Flex, Button } from '@mixins';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { BackButton } from '../EditUserProfile/styles';

const EditCompanyProfile = ({
  error,
  onBackClick,
  isConfirmationModalOpen,
  onDiscardChanges,
  hasChanges,
  onAboutUsSave,
  onIndustrySave,
  userIndustry,
  onWebsiteChange,
  isLoading,
  aboutUs,
  website,
  companyName,
  industryList,
  socials,
  onSave,
}) => {
  const [isAboutUsModalOpen, setIsAboutUsModalOpen] = useState(false);
  const [isIndustryModalOpen, setIsIndustryModalOpen] = useState(false);

  const handleAboutUsModalChangeState = useCallback(() => {
    setIsAboutUsModalOpen(prevValue => !prevValue);
  }, []);

  const handleIndustryModalChangeState = useCallback(() => {
    setIsIndustryModalOpen(prevValue => !prevValue);
  }, []);

  const handleDiscardChanges = useCallback(() => {
    onDiscardChanges();
  }, []);

  const handleAboutUsSave = useCallback(newAbousUs => {
    onAboutUsSave(newAbousUs);
  }, []);

  const handleIndustrySave = useCallback(newIndustry => {
    onIndustrySave(newIndustry);
    handleIndustryModalChangeState();
  }, []);

  return (
    <Main isLoading={isLoading} error={error}>
      <Flex mb={[25, 30, 40]} width="100%" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" justifyContent="center">
          <Flex width="fit-content" mb={[20, 25, 30]} onClick={onBackClick} pointer alignItems="center">
            <BackButton mr={['9px', 11, 13]} color={theme.color.gray[500]} SVG={Dropdown} />
            <Typography variant="proximaNova-400">Back</Typography>
          </Flex>
          <Typography variant="proximaNova-600" fontSize={[26, 30, 34]}>
            Edit Company Profile
          </Typography>
        </Flex>
        <Button
          py={['8px', 10, 12]}
          px={[40, 50, 60]}
          variant="primary"
          fontSize={[12, 14, 16]}
          onClick={onSave}
          disabled={!hasChanges}>
          Save
        </Button>
      </Flex>
      <Flex width="100%">
        <Flex
          height="fit-content"
          p={[20, 30, 40]}
          mr={['6px', '8px', 10]}
          minWidth={[150, 200, 300, 370]}
          border={`1px solid ${theme.color.gray[100]}`}
          flexDirection="column"
          justifyContent="center">
          <Typography mb={['9px', 12, 15]} variant="garamond-500" fontSize={[16, 20, 24]}>
            Company Name
          </Typography>
          <Typography color={theme.color.gray[300]} variant="proximaNova-400" fontSize={[12, 14, 16]}>
            {companyName}
          </Typography>
        </Flex>
        <Flex py={[20, 30, 40]} px={[25, 40, 55]} border={`1px solid ${theme.color.gray[100]}`} width="100%">
          <Flex width="100%" flexDirection="column" maxWidth={[220, 340, 480]}>
            <Typography mb={['9px', 12, 15]} variant="garamond-500" fontSize={[16, 20, 24]}>
              About us
            </Typography>
            {aboutUs && (
              <Typography fontSize={[10, 14, 16]} variant="proximaNova-400" mb={['9px', 12, 15]}>
                {aboutUs}
              </Typography>
            )}
            <Typography
              pointer
              onClick={handleAboutUsModalChangeState}
              mb={[25, 35, 45]}
              fontSize={[10, 14, 16]}
              color={theme.color.darkGreen}
              variant="proximaNova-400">
              {aboutUs ? 'Edit' : 'Let everyone know what you’re all about'}
            </Typography>
            <Typography mb={['9px', 12, 15]} variant="garamond-500" fontSize={[16, 20, 24]}>
              Industry
            </Typography>
            {userIndustry && (
              <Typography fontSize={[10, 14, 16]} variant="proximaNova-400" mb={['9px', 12, 15]}>
                {userIndustry}
              </Typography>
            )}
            <Typography
              pointer
              onClick={handleIndustryModalChangeState}
              fontSize={[10, 14, 16]}
              variant="proximaNova-400"
              color={theme.color.darkGreen}
              mb={[25, 35, 45]}>
              {`Add${Object.keys(userIndustry).length ? ' / Edit' : ''} industry`}
            </Typography>
            <Typography mb={['9px', 12, 15]} variant="garamond-500" fontSize={[16, 20, 24]}>
              Website
            </Typography>
            <TextInput placeholder="Enter website" value={website} onChange={onWebsiteChange} />
            <Typography mb={['9px', 12, 15]} mt={[25, 35, 45]} variant="garamond-500" fontSize={[16, 20, 24]}>
              Social Networks
            </Typography>
            <EditSocials isCompany socials={socials} />
          </Flex>
        </Flex>
      </Flex>
      {isAboutUsModalOpen && (
        <TextModal
          title="About Us"
          inputPlaceholder="Let everyone know what you’re all about"
          onSave={handleAboutUsSave}
          isOpen={isAboutUsModalOpen}
          onModalClose={handleAboutUsModalChangeState}
          initialText={aboutUs}
        />
      )}
      {isIndustryModalOpen && (
        <SearchListModal
          withMultipleSelection={false}
          onSelect={handleIndustrySave}
          title="Industry"
          itemsList={industryList}
          isOpen={isIndustryModalOpen}
          selectedItemsList={[userIndustry]}
          onModalClose={handleIndustryModalChangeState}
        />
      )}
      <ConfirmationModal
        title="Leaving without saving?"
        text="If you quit this page now, your changes won’t be saved."
        onPrimaryButtonClick={onSave}
        onSecondaryButtonClick={handleDiscardChanges}
        isOpen={isConfirmationModalOpen}
        primaryButtonText="Save changes"
        secondaryButtonText="Discard changes"
      />
    </Main>
  );
};

EditCompanyProfile.defaultProps = {
  companyName: '',
  industryList: [],
  error: {},
};

EditCompanyProfile.propTypes = {
  error: exact({
    status: number,
    message: string,
  }),
  onBackClick: func.isRequired,
  isConfirmationModalOpen: bool.isRequired,
  onDiscardChanges: func.isRequired,
  hasChanges: bool.isRequired,
  onWebsiteChange: func.isRequired,
  onAboutUsSave: func.isRequired,
  onIndustrySave: func.isRequired,
  onSave: func.isRequired,
  socials: arrayOf(
    exact({
      name: string,
      value: string,
      placeholder: string,
      handler: func,
      icon: shape({}),
    }),
  ).isRequired,
  companyName: string,
  aboutUs: string.isRequired,
  website: string.isRequired,
  isLoading: bool.isRequired,
  industryList: arrayOf(string),
  userIndustry: string.isRequired,
};

export default EditCompanyProfile;
