import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import { storage } from '@utils';
import { getCurrentLocation } from '@store/location/duck';
import { getMetadataNavigation } from '@store/metadata/duck';

import * as api from './api';

// < ----- ACTIONS ----- > //
const loginAction = 'LOGIN';
const refreshTokenAction = 'REFRESH_TOKEN';
const setActivatedAction = 'SET_ACTIVATED';
const resetPhasesAction = 'RESET_USER_PHASES';
const resetStoreToInitialStateAction = 'RESET_STORE';
const setDiscountModalDisplayedAction = 'SET_DISCOUNT_MODAL_DISPLAYED';
const getCurrentUserRoutine = createRoutine(
  'GET_CURRENT_USER_INFO',
);
const editUserRoutine = createRoutine(
  'EDIT_USER',
);
const getUserByIdRoutine = createRoutine(
  'GET_USER_BY_ID',
);
const sendSupportTicketRoutine = createRoutine(
  'SEND_SUPPORT_TICKET',
);

const sendFeedbackRoutine = createRoutine(
  'SEND_FEEDBACK',
);

const getUserSettingsRoutine = createRoutine(
  'GET_USER_SETTINGS',
);

const updateUserSettingsRoutine = createRoutine(
  'UPDATE_USER_SETTINGS',
);

// < ----- ACTION CREATORS ----- > //
export const login = data => ({
  type: loginAction,
  payload: data,
});

export const refreshToken = () => ({
  type: refreshTokenAction,
});

export const setActivated = data => ({
  type: setActivatedAction,
  payload: data,
});

export const setDiscountModalDisplayed = data => ({
  type: setDiscountModalDisplayedAction,
  payload: data,
});

export const resetPhases = () => ({
  type: resetPhasesAction,
});

export const resetStore = () => ({
  type: resetStoreToInitialStateAction,
});

export const getCurrentUser = () => async dispatch => {
  try {
    dispatch(getCurrentUserRoutine.request());

    const response = await api.getCurrentUser();

    dispatch(getCurrentUserRoutine.success(response.data));

    storage.setUserId(response.data.data.id);

    dispatch(getMetadataNavigation(response.data.data?.locationId));
    return dispatch(getCurrentLocation(response.data.data?.locationId));
  } catch (error) {
    return dispatch(getCurrentUserRoutine.failure(error.response));
  }
};

export const getUserById = id => async dispatch => {
  try {
    dispatch(getUserByIdRoutine.request());

    const response = await api.getUserById(id);

    return dispatch(getUserByIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getUserByIdRoutine.failure(error.response));
  }
};

export const getUserSettings = () => async dispatch => {
  try {
    dispatch(getUserSettingsRoutine.request());

    const response = await api.getUserSettings();

    return dispatch(getUserSettingsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getUserSettingsRoutine.failure(error.response));
  }
};

export const sendFeedback = data => async dispatch => {
  try {
    dispatch(sendFeedbackRoutine.request());

    await api.sendFeedback(data);

    return dispatch(sendFeedbackRoutine.success());
  } catch (error) {
    return dispatch(sendFeedbackRoutine.failure(error.response));
  }
};

export const sendSupportTicket = data => async dispatch => {
  try {
    dispatch(sendSupportTicketRoutine.request());

    await api.sendSupportTicket(data);

    return dispatch(sendSupportTicketRoutine.success());
  } catch (error) {
    return dispatch(sendSupportTicketRoutine.failure(error.response));
  }
};

export const updateUserSettings = data => async dispatch => {
  try {
    dispatch(updateUserSettingsRoutine.request());

    const response = await api.updateUserSettings(data);

    return dispatch(updateUserSettingsRoutine.success(response.data));
  } catch (error) {
    return dispatch(updateUserSettingsRoutine.failure(error.response));
  }
};

export const editUser = data => async dispatch => {
  try {
    dispatch(editUserRoutine.request());

    const response = await api.editUser(data);

    return dispatch(editUserRoutine.success(response.data));
  } catch (error) {
    return dispatch(editUserRoutine.failure(error.response));
  }
};

// < ----- STATE ----- > //
export const userStorePersistWhitelist = [];

const initialState = {
  user: {
    cognitoUser: {},
  },
  userById: {},
  isActivated: false,
  settings: {},

  getCurrentUserPhase: INIT,
  getCurrentUserError: null,

  getUserSettingsPhase: INIT,
  getUserSettingsError: null,

  updateUserSettingsPhase: INIT,
  updateUserSettingsError: null,

  getUserByIdPhase: INIT,
  getUserByIdError: null,

  editUserPhase: INIT,
  editUserError: null,

  sendFeedbackPhase: INIT,
  sendFeedbackError: null,

  sendSupportTicketPhase: INIT,
  sendSupportTicketError: null,

  refreshTokenPhase: INIT,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;
    case loginAction:
      return {
        ...state,
        user: {
          ...state.user,
          cognitoUser: payload.cognitoUser,
        },
      };
    case setActivatedAction:
      return {
        ...state,
        isActivated: payload,
      };
    case setDiscountModalDisplayedAction:
      return {
        ...state,
        user: {
          ...state.user,
          shouldDisplayDiscountModal: !payload,
        },
      };
    case resetPhasesAction:
      return {
        ...state,
        getCurrentUserPhase: INIT,
        editUserPhase: INIT,
        getUserByIdPhase: INIT,
        sendFeedbackPhase: INIT,
        sendSupportTicketPhase: INIT,
        getUserSettingsPhase: INIT,
        updateUserSettingsPhase: INIT,
        refreshTokenPhase: INIT,
        getUserSettingsError: null,
        updateUserSettingsError: null,
        getCurrentUserError: null,
        editUserError: null,
        getUserByIdError: null,
        sendFeedbackError: null,
      };
    // REFRESH TOKEN
    case refreshTokenAction:
      return {
        ...state,
        refreshTokenPhase: LOADING,
      };
    // GET CURRENT USER
    case getCurrentUserRoutine.REQUEST:
      return {
        ...state,
        getCurrentUserPhase: LOADING,
      };
    case getCurrentUserRoutine.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
          imageUrl: payload.data.imageUrl ? `${payload.data.imageUrl}?t=${Date.now()}` : null,
        },
        getCurrentUserPhase: SUCCESS,
      };
    case getCurrentUserRoutine.FAILURE:
      return {
        ...state,
        getCurrentUserPhase: FAILURE,
        getCurrentUserError: payload,
      };
    // GET USER BY ID
    case getUserByIdRoutine.REQUEST:
      return {
        ...state,
        getUserByIdPhase: LOADING,
      };
    case getUserByIdRoutine.SUCCESS:
      return {
        ...state,
        userById: payload.data,
        getUserByIdPhase: SUCCESS,
      };
    case getUserByIdRoutine.FAILURE:
      return {
        ...state,
        getUserByIdPhase: FAILURE,
        getUserByIdError: payload,
      };
    // GET USER SETTINGS
    case getUserSettingsRoutine.REQUEST:
      return {
        ...state,
        getUserSettingsPhase: LOADING,
      };
    case getUserSettingsRoutine.SUCCESS:
      return {
        ...state,
        settings: payload.data,
        getUserSettingsPhase: SUCCESS,
      };
    case getUserSettingsRoutine.FAILURE:
      return {
        ...state,
        getUserSettingsPhase: FAILURE,
        getUserSettingsError: payload,
      };
    // UPDATE USER SETTINGS
    case updateUserSettingsRoutine.REQUEST:
      return {
        ...state,
        updateUserSettingsPhase: LOADING,
      };
    case updateUserSettingsRoutine.SUCCESS:
      return {
        ...state,
        settings: payload.data,
        updateUserSettingsPhase: SUCCESS,
      };
    case updateUserSettingsRoutine.FAILURE:
      return {
        ...state,
        updateUserSettingsPhase: FAILURE,
        updateUserSettingsError: payload,
      };
    // SEND SUPPORT TICKET
    case sendSupportTicketRoutine.REQUEST:
      return {
        ...state,
        sendSupportTicketPhase: LOADING,
      };
    case sendSupportTicketRoutine.SUCCESS:
      return {
        ...state,
        sendSupportTicketPhase: SUCCESS,
      };
    case sendSupportTicketRoutine.FAILURE:
      return {
        ...state,
        sendSupportTicketPhase: FAILURE,
        sendSupportTicketError: payload,
      };
    // SEND FEEDBACK
    case sendFeedbackRoutine.REQUEST:
      return {
        ...state,
        sendFeedbackPhase: LOADING,
      };
    case sendFeedbackRoutine.SUCCESS:
      return {
        ...state,
        sendFeedbackPhase: SUCCESS,
      };
    case sendFeedbackRoutine.FAILURE:
      return {
        ...state,
        sendFeedbackPhase: FAILURE,
        sendFeedbackError: payload,
      };
    // EDIT USER
    case editUserRoutine.REQUEST:
      return {
        ...state,
        editUserPhase: LOADING,
      };
    case editUserRoutine.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
          imageUrl: payload.data.imageUrl ? `${payload.data.imageUrl}?t=${Date.now()}` : null,
        },
        editUserPhase: SUCCESS,
      };
    case editUserRoutine.FAILURE:
      return {
        ...state,
        editUserPhase: FAILURE,
        editUserError: payload,
      };
    default:
      return state;
  }
};

export default store;
