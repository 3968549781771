import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { KnowYourNeighbors as View } from '@views';
import { routes } from '@constants';
import { LOADING, SUCCESS } from '@constants/requestPhase';
import { useDebounce } from '@hooks';
import { getCompanyList } from '@store/company/duck';
import { getLocationNeighbors } from '@store/location/duck';
import { trackEvent } from '@utils/mixpanel';

const KnowYourNeighbors = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentLocation, getLocationNeighborsPhase, companies, members } =
    useSelector(store => store.locationStore);

  const {
    companies: { pagination },
    getCompanyListPhase,
  } = useSelector(store => store.companyStore);

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const debouncedInput = useDebounce(search, 300);

  const handleSearchInputChange = useCallback(e => {
    setSearch(e.target.value);
  }, []);

  useEffect(() => {
    const pageTitle = 'Mindspace: Know Your Neighbors';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Know Your Members',
    });
  }, []);

  const handleClickLoadMore = useCallback(() => {
    dispatch(
      getCompanyList({
        locationId: currentLocation.id,
        search: debouncedInput,
        page: pagination.page + 1,
        pageSize: 6,
      }),
    );
  }, [pagination, currentLocation, debouncedInput]);

  useEffect(() => {
    if (currentLocation.id) {
      setIsLoading(true);

      dispatch(getLocationNeighbors(currentLocation.id));
      dispatch(
        getCompanyList({
          locationId: currentLocation.id,
          page: 1,
          pageSize: 6,
        }),
      );
    }
  }, [currentLocation.id]);

  useEffect(() => {
    if (currentLocation.id) {
      dispatch(
        getCompanyList({
          locationId: currentLocation.id,
          search: debouncedInput,
          page: 1,
          pageSize: 6,
        }),
      );
    }
  }, [debouncedInput]);

  useEffect(() => {
    if (
      getCompanyListPhase === SUCCESS &&
      getLocationNeighborsPhase === SUCCESS
    ) {
      if (!companies) {
        history.push(routes.home);
      }

      setIsLoading(false);
    }
  }, [getCompanyListPhase, getLocationNeighborsPhase]);

  return (
    <View
      areNeighborsLoading={getCompanyListPhase === LOADING && !pagination.page}
      isLoading={isLoading}
      onSearchInputChange={handleSearchInputChange}
      members={members}
      companies={companies}
      search={search}
      onClickLoadMore={handleClickLoadMore}
    />
  );
};

export default KnowYourNeighbors;
