import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { bool, number, shape, string, arrayOf, exact, func } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '@atoms';
import { RoomPhotosCarousel, RoomDetailsPanel, ConfirmationModal, SuccessModal } from '@molecules';
import { GuestInvitation, BookedRoomButtonsPanel } from '@organisms';
import { Main } from '@templates';
import { Flex, Typography, Button } from '@mixins';
import { theme } from '@styles';
import { dayjs } from '@utils';
import { getMyCompanyAvailableCredits } from '@store/company/duck';
import { resetPhases } from '@store/booking/duck';
import { SUCCESS, FAILURE } from '@constants/requestPhase';
import { getSuffixByNumericValue, getCurrency } from '@helpers/common';
import { formatTime, getDateLabel } from '@helpers/time';
import { routes } from '@constants';
import {
  CMS_EMPLOYEE_ROLE,
  EMPLOYEE_ROLE,
  COMMUNITY_MANAGER_ROLE,
  ADMIN_ROLE,
  EMPLOYEE_CM_ROLE,
} from '@constants/common';
import { refundType } from '@constants/refunds';

import { ReactComponent as Booked } from '@assets/svgs/BookedRoom.svg';
import { ReactComponent as BookedEventSpace } from '@assets/svgs/BookedEventSpace.svg';
import { ReactComponent as Arrow } from '@assets/svgs/ArrowSmall.svg';

const BookedRoom = ({
  bookedForCompany,
  refundTo,
  creditPrices,
  isEventSpace,
  isActivate,
  companyName,
  onCancelBooking,
  id,
  address,
  roomPhotos,
  endDate,
  startDate,
  isLoading,
  features,
  number: roomNumber,
  floor,
  currencyPrice,
  creditsPrice,
  seats,
  title,
  creditsSpent,
  currency,
  paymentType,
  moneySpent,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { currentLocation } = useSelector(store => store.locationStore);

  const { availableCredits } = useSelector(store => store.companyStore);

  const { user } = useSelector(store => store.userStore);

  const {
    cancelBookingPhase,
    cancelBookingError,
    refundedCredits,
    refundedMoney,
    bookingById: { company, meetingRoom },
    companyBookingAvailableCredits,
  } = useSelector(store => store.bookingStore);

  const isAdmin = user.role === COMMUNITY_MANAGER_ROLE || user.role === ADMIN_ROLE || user.role === EMPLOYEE_CM_ROLE;
  const isMindspaceEmployee = isAdmin || user.role === EMPLOYEE_ROLE || user.role === CMS_EMPLOYEE_ROLE;

  const [isInvitationOpen, setIsInvitationOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isBookingCanceled, setIsBookingCancelled] = useState(false);
  const [error, setError] = useState({});

  const bookingTime = useMemo(
    () =>
      `${formatTime(
        startDate,
        meetingRoom?.location.timeFormat,
        '',
        meetingRoom?.location.timeZone,
      )} - ${formatTime(endDate, meetingRoom?.location.timeFormat, '', meetingRoom?.location.timeZone)}`,
    [startDate, endDate, meetingRoom],
  );

  const cancelSuccessDescription = useCallback(() => {
    if (!refundTo || refundTo === refundType.TO_ACTIVE) {
      if (refundedCredits) {
        return `${refundedCredits} ${
          refundedCredits > 1 ? 'credits have' : 'credit has'
        } been returned to ${company ? company.name : companyName}
      `;
      }

      if (refundedMoney) {
        return `A refund of ${getCurrency(currentLocation.currency, refundedMoney)} has been issued.`;
      }
    }

    return '';
  }, [refundedCredits, refundedMoney, company, companyName, currentLocation.currency, refundTo]);

  const handleCommunityTeamClick = () => {
    const date = getDateLabel(startDate, meetingRoom?.location.timeZone, true);
    window.location.href = `mailto:${currentLocation.cmTeamEmail}
    ?subject=Additional Services needed for an Event on ${date} ${bookingTime}`;
  };

  const handleCancelBookingClick = () => {
    if (isMindspaceEmployee) {
      handleCancelConfirm();
    } else {
      handleCancelModalChangeState();
    }
  };

  const handleCancelBookingChangeState = useCallback(() => {
    setIsBookingCancelled(prevState => !prevState);
  }, []);

  useEffect(() => {
    if (cancelBookingPhase === SUCCESS) {
      dispatch(resetPhases());
      dispatch(getMyCompanyAvailableCredits());
      handleCancelBookingChangeState();
    }
    if (cancelBookingPhase === FAILURE) {
      setError({
        status: cancelBookingError.data.error.status,
        message: cancelBookingError.data.error.message,
      });
      dispatch(resetPhases());
    }
  }, [cancelBookingPhase]);

  const handleInviteGuestsModalChangeState = useCallback(() => {
    setIsInvitationOpen(prevValue => !prevValue);
  }, []);

  const handleCancelModalChangeState = useCallback(() => {
    setIsCancelModalOpen(prevValue => !prevValue);
  }, []);

  const handleCancelConfirm = useCallback(() => {
    setIsCancelModalOpen(false);
    onCancelBooking();
  }, []);

  const handleDoneClick = useCallback(() => {
    setIsBookingCancelled(false);
    history.push(routes.home);
  }, []);

  const isModifyingAvailable = useMemo(
    () => dayjs(startDate) >= dayjs().add(isActivate || isEventSpace ? 24 : 1, 'hour'),
    [startDate, isActivate, isEventSpace],
  );

  const cancelationModalText = useMemo(() => {
    if (refundTo && refundTo !== refundType.TO_ACTIVE) {
      return 'This booking is not eligible for a full refund.';
    }
    if (isModifyingAvailable) {
      return `${paymentType === 'credits' ? 'Credits' : 'Money'} will be fully refunded for this booking.`;
    }
    return `${paymentType === 'credits' ? 'Credits' : 'Money'} will not be refunded for this booking as canceling ${
      isActivate || isEventSpace ? '24hrs' : '1hr'
    } or less before its start time.`;
  }, [refundTo, isActivate, isEventSpace, paymentType, isModifyingAvailable]);

  const details = [
    {
      title: 'Date',
      value: getDateLabel(startDate, meetingRoom?.location.timeZone),
      isShown: true,
    },
    {
      title: 'Time',
      value: bookingTime,
      isShown: true,
    },
    {
      title: 'Meeting Title',
      value: title,
      isShown: !!title,
    },
    {
      title: paymentType === 'money' ? 'Total paid' : 'Credits Used',
      value: paymentType === 'money' ? getCurrency(currency, moneySpent) : creditsSpent.toFixed(2),
      isShown: paymentType === 'money' ? !!moneySpent : !!creditsSpent,
    },
    {
      title: 'Booked For',
      value: bookedForCompany,
      isShown: !!bookedForCompany,
    },
  ];

  const detailsBlock = useMemo(
    () =>
      details.reduce((acc, { isShown, title: blockTitle, value }) => {
        if (isShown) {
          acc.push(
            <Flex mb={10} key={blockTitle}>
              <Typography mr={[30, 55]} width={[60, 80]} variant="proximaNova-400" fontSize={[10, 14]}>
                {blockTitle}
              </Typography>
              <Flex>
                <Typography variant="proximaNova-600" fontSize={[10, 14]}>
                  {value}
                  {blockTitle === 'Credits Used' && (
                    <Typography as="span" color={theme.color.gray[200]} variant="proximaNova-400" fontSize={[10, 14]}>
                      {` (${company ? companyBookingAvailableCredits : availableCredits} Remaining)`}
                    </Typography>
                  )}
                </Typography>
              </Flex>
            </Flex>,
          );
        }
        return acc;
      }, []),
    [details],
  );

  return (
    <Main isWhiteLabelingOpen={false} isLoading={isLoading} error={error}>
      <Flex justifyContent="space-between" width="100%">
        <Flex
          flexDirection="column"
          width="100%"
          maxWidth={['calc(100% - 260px)', 'calc(100% - 310px)', 'calc(100% - 390px)', 'calc(100% - 490px)']}>
          <Flex alignItems="end" mb={[20, 30]}>
            <Typography lineHeight={1} mr={[10, 12]} variant="garamond-500" fontSize={[25, 32, 34]}>
              {`${roomNumber} · ${getSuffixByNumericValue(floor)} floor`}
            </Typography>
            <Typography
              lineHeight={1}
              marginBottom="5px"
              borderRadius="2px"
              variant="proximaNova-600"
              fontSize={[10, 12]}
              px={['6px', 10]}
              py="5px"
              backgroundColor={theme.color.pink}>
              Booked
            </Typography>
          </Flex>
          <Flex width="100%">
            <Flex mr={[20, 30, 40, 50]} flexGrow={1} flexDirection="column">
              <RoomDetailsPanel
                creditPrices={creditPrices}
                isActivate={meetingRoom?.location.isWhiteLabeled}
                currencyPrice={currencyPrice}
                creditsPrice={creditsPrice}
                features={features}
                seats={seats}
              />
              <Flex {...(isEventSpace && { borderBottom: '1px solid #EFECE6', pb: 20 })} flexDirection="column" mt={30}>
                {detailsBlock}
              </Flex>
              {isEventSpace ? (
                <Flex mt={15} padding="20px" backgroundColor="#EFECE6" width="85%" borderRadius="2px">
                  <Icon minWidth={[35, 50, 65]} SVG={BookedEventSpace} />
                  <Flex ml="12px" flexDirection="column">
                    <Typography mb="10px" variant="proximaNova-600" fontSize={[10, 12, 14]}>
                      Thank you for booking our event space!
                    </Typography>
                    <Typography
                      color={theme.color.gray[300]}
                      mb="6px"
                      variant="proximaNova-400"
                      fontSize={[10, 12, 14]}>
                      If you require any additional services for your event, such as cleaning, catering, seating
                      arrangements, or other support, please reach out to your local Community Team.
                    </Typography>
                    <Typography
                      color={theme.color.gray[300]}
                      mb="10px"
                      variant="proximaNova-400"
                      fontSize={['8px', 10]}>
                      *Please note that separate cancellation policy applies for any additional services.
                    </Typography>
                    <Flex py={10} alignItems="center" pointer onClick={handleCommunityTeamClick}>
                      <Typography variant="proximaNova-600" fontSize={[10, 12, 16]} color={theme.color.darkGreen}>
                        Contact Community Team
                      </Typography>
                      <Icon ml="30px" color={theme.color.darkGreen} SVG={Arrow} />
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  mt={90}
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  py={50}
                  border="1px solid #EFECE6"
                  width="100%">
                  <Icon SVG={Booked} mb={20} />
                  <Typography mb="5px" variant="proximaNova-600" fontSize={[14, 16, 18, 20]}>
                    Have a productive meeting!
                  </Typography>
                  <Typography variant="proximaNova-400" fontSize={[10, 10, 12, 14]}>
                    Go get &apos;em
                  </Typography>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          width="100%"
          maxWidth={[250, 300, 380, 480]}
          flexDirection="column"
          border="1px solid #EFECE6"
          height="fit-content">
          <Flex flexDirection="column" pt={[10, 15, 20, 30]} px={[10, 15, 20, 30]}>
            <Flex width="100%" height={[100, 140, 180, 260]}>
              <RoomPhotosCarousel photos={roomPhotos} />
            </Flex>
            <Button
              py={['8px', 10, 12]}
              mb={20}
              mt={25}
              width="100%"
              variant="primary"
              fontSize={[12, 14, 16]}
              onClick={handleDoneClick}>
              Done
            </Button>
            <BookedRoomButtonsPanel
              isModifyingAvailable={isModifyingAvailable}
              id={id}
              title={title}
              endDate={endDate}
              startDate={startDate}
              address={address}
              onCancelBooking={handleCancelBookingClick}
              onInviteGuests={handleInviteGuestsModalChangeState}
            />
          </Flex>
          <Typography
            px={[10, 15, 20, 30]}
            color={theme.color.gray[300]}
            variant="proximaNova-400"
            fontSize={[10, 12, 12, 14]}
            my={[10, 15, 20, 25]}>
            {`You can cancel or modify up to ${
              isActivate || isEventSpace ? '24 hours' : '1 hour'
            } before the booking start time and receive a full refund${isActivate ? '.' : ' of credits.'}`}
          </Typography>
        </Flex>
        {isInvitationOpen && (
          <GuestInvitation
            room={{
              bookingId: id,
              title,
              number: roomNumber,
              floor,
              startDate,
              roomPhotos,
            }}
            onClose={handleInviteGuestsModalChangeState}
            isOpen={isInvitationOpen}
          />
        )}
        {isCancelModalOpen && (
          <ConfirmationModal
            onPrimaryButtonClick={handleCancelConfirm}
            onSecondaryButtonClick={handleCancelModalChangeState}
            isOpen={isCancelModalOpen}
            title="Cancel booking?"
            text={cancelationModalText}
            primaryButtonText="Yes, cancel"
            secondaryButtonText="No, keep it"
          />
        )}
        {isBookingCanceled && (
          <SuccessModal
            text={cancelSuccessDescription}
            onDoneClick={handleDoneClick}
            title="Booking Cancelled"
            isOpen={isBookingCanceled}
          />
        )}
      </Flex>
    </Main>
  );
};

BookedRoom.defaultProps = {
  bookedForCompany: '',
  refundTo: '',
  creditPrices: {},
  address: '',
  roomPhotos: [],
  features: [],
  number: '',
  floor: 0,
  seats: 0,
  creditsSpent: 0,
  creditsPrice: 0,
  currencyPrice: 0,
  title: '',
  startDate: new Date(),
  endDate: new Date(),
  companyName: '',
  isActivate: false,
  currency: 'EUR',
  paymentType: 'credits',
  moneySpent: 0,
};

BookedRoom.propTypes = {
  bookedForCompany: string,
  refundTo: string,
  creditPrices: exact({
    base: exact({
      creditsPerHour: number,
    }),
    peakPrice: exact({
      creditsPerHour: number,
      timeFrames: exact({
        daysOfWeek: number,
        times: arrayOf(
          exact({
            from: string,
            to: string,
          }),
        ),
      }),
    }),
  }),
  isEventSpace: bool.isRequired,
  currency: string,
  paymentType: string,
  moneySpent: number,
  companyName: string,
  onCancelBooking: func.isRequired,
  id: string.isRequired,
  address: string,
  title: string,
  number: string,
  floor: number,
  seats: number,
  isLoading: bool.isRequired,
  startDate: shape({}),
  endDate: shape({}),
  features: arrayOf(
    exact({
      id: number,
      key: string,
      iconUrl: string,
      displayName: string,
    }),
  ),
  roomPhotos: arrayOf(string),
  creditsSpent: number,
  creditsPrice: number,
  currencyPrice: number,
  isActivate: bool,
};

export default BookedRoom;
