import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Home as View } from '@views';
import { setActivated } from '@store/user/duck';
import { getBookedRooms } from '@store/booking/duck';
import { getEventsCategories } from '@store/event/duck';
import { getAnnouncements } from '@store/announcement/duck';
import { LOADING, SUCCESS } from '@constants/requestPhase';
import { HEADER_ANNOUNCEMENT_KEY } from '@constants/common';
import { storage } from '@utils';
import { trackEvent } from '@utils/mixpanel';

const Home = () => {
  const { isActivated, user } = useSelector(store => store.userStore);

  const headerAnnouncement = storage.getFromLocalStorage(
    HEADER_ANNOUNCEMENT_KEY,
  );

  const parsedHeaderAnnouncement = headerAnnouncement
    ? JSON.parse(headerAnnouncement)
    : {
      id: null,
      locationId: null,
    };

  const { currentLocation, getCurrentLocationPhase } = useSelector(
    store => store.locationStore,
  );

  const { announcements } = useSelector(store => store.announcementsStore);

  const { getEventsCategoriesPhase } = useSelector(store => store.eventStore);

  const { getMeetingRoomsPhase } = useSelector(store => store.roomStore);

  const { isWhiteLabeled } = currentLocation;

  const [isWhiteLabelingOpen, setIsWhiteLabelingOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const pageTitle = 'Mindspace: Home';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Home',
    });

    dispatch(getBookedRooms());
  }, []);

  useEffect(() => {
    if (
      getCurrentLocationPhase === SUCCESS &&
        parsedHeaderAnnouncement.locationId !== currentLocation?.id
    ) {
      dispatch(getAnnouncements(currentLocation?.id));
    }
  }, [getCurrentLocationPhase]);

  useEffect(() => {
    if (
      Object.keys(currentLocation).length &&
      !currentLocation.exploreLocation
    ) {
      dispatch(getEventsCategories());
    }
  }, [currentLocation]);

  useEffect(() => {
    setIsWhiteLabelingOpen(
      isActivated && isWhiteLabeled && location?.state?.from === 'login',
    );
  }, [isWhiteLabeled]);

  useEffect(() => {
    if (isWhiteLabelingOpen) {
      setTimeout(() => {
        setIsWhiteLabelingOpen(false);
        dispatch(setActivated(false));
      }, 3500);
    }
  }, [isWhiteLabelingOpen]);

  const locationTeam = useMemo(
    () =>
      currentLocation.communityManagers?.map(
        ({ id, firstName, lastName, role, jobTitle, imageUrl }) => ({
          id,
          firstName,
          lastName,
          role,
          jobTitle,
          imageUrl,
        }),
      ),
    [currentLocation.id],
  );

  return (
    <View
      isLoading={
        getEventsCategoriesPhase === LOADING ||
        getMeetingRoomsPhase === LOADING ||
        getCurrentLocationPhase === LOADING
      }
      firstName={user.firstName}
      location={currentLocation.title}
      upcomingEvent={currentLocation.upcomingEvent}
      locationTeam={locationTeam}
      locationLongitude={currentLocation.longtitude}
      locationLatitude={currentLocation.latitude}
      locationAddress={currentLocation.address}
      locationImage={currentLocation.image}
      wifiSettings={currentLocation.wifiSettings}
      locationLogo={currentLocation.logo}
      isWhiteLabelingOpen={isWhiteLabelingOpen}
      announcement={announcements?.announcement}
      headerAnnouncement={announcements?.heads_up}
      parsedHeaderAnnouncementSavedLocally={parsedHeaderAnnouncement}
      isDiscountAvailable={user.shouldDisplayDiscountModal}
    />
  );
};

export default Home;
