import { ReactComponent as HDMI } from '@assets/svgs/HDMI.svg';
import { ReactComponent as AppleTV } from '@assets/svgs/AppleTV.svg';
import { ReactComponent as VideoConferencing } from '@assets/svgs/VideoConferencing.svg';
import { ReactComponent as Whiteboard } from '@assets/svgs/Whiteboard.svg';
import { ReactComponent as Showers } from '@assets/svgs/Showers.svg';
import { ReactComponent as Bar } from '@assets/svgs/Bar.svg';
import { ReactComponent as Kitchen } from '@assets/svgs/Kitchen.svg';
import { ReactComponent as SofaLounge } from '@assets/svgs/SofaLounge.svg';
import { ReactComponent as BicycleRoom } from '@assets/svgs/BicycleRoom.svg';
import { ReactComponent as Terrace } from '@assets/svgs/Terrace.svg';
import { ReactComponent as Cafe } from '@assets/svgs/Cafe.svg';
import { ReactComponent as Auditorium } from '@assets/svgs/Auditorium.svg';
import { ReactComponent as GridView } from '@assets/svgs/GridView.svg';
import { ReactComponent as CleaningBucket } from '@assets/svgs/CleaningBucket.svg';
import { ReactComponent as CreditCard } from '@assets/svgs/CreditCard.svg';
import { ReactComponent as Engineering } from '@assets/svgs/Engineering.svg';
import { ReactComponent as SmartToy } from '@assets/svgs/SmartToy.svg';
import { ReactComponent as BoundingBox } from '@assets/svgs/BoundingBox.svg';
import { ReactComponent as Sales } from '@assets/svgs/Sales.svg';
import Events from '@assets/images/Events.png';
import Events_1 from '@assets/images/Events_1.png';
import Events_2 from '@assets/images/Events_2.png';
import Events_3 from '@assets/images/Events_3.png';
import Events_4 from '@assets/images/Events_4.png';
import FitnessClasses from '@assets/images/FitnessClasses.jpg';
import Lifestyle from '@assets/images/LifestyleEvent.png';
import Professional from '@assets/images/ProfessionalEvent.png';
import Fun from '@assets/images/FunEvent.png';
import GiveBack from '@assets/images/GiveBackEvent.png';

export const REGEXP_EMAIL =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const ENV = process.env.REACT_APP_ENV;

export const TOKEN = 'token';
export const USER_ID = 'userId';
export const AMPLIFY_AUTH_USER = 'amplifyAuthUser';

export const EMPLOYEE_CM_ROLE = 'employee_cm';
export const COMPANY_ADMIN_ROLE = 'company_admin';
export const ADMIN_ROLE = 'admin';
export const COMMUNITY_MANAGER_ROLE = 'community_manager';
export const EMPLOYEE_ROLE = 'employee';
export const CMS_EMPLOYEE_ROLE = 'cms_employee';

export const BOOKING_DEFAULT_TITLE = 'The Idea Fiesta';

export const BACKSPACE_KEY = 'Backspace';

export const HEADER_ANNOUNCEMENT_KEY = 'viewedHeaderAnnouncement';
export const IS_DISCOUNT_DISPLAYED_KEY = 'isDiscountDisplayed';

export const ONE_MINUTE_MILLI = 60000;
export const ONE_HOUR_MILLI = ONE_MINUTE_MILLI * 60;
export const ONE_DAY_MILLI = ONE_HOUR_MILLI * 24;
export const ONE_WEEK_MILLI = ONE_DAY_MILLI * 7;

export const TIMELINE_INTERVAL_MINUTES = 30;

export const ICON_BY_KEY = {
  whiteboard: Whiteboard,
  apple_tv: AppleTV,
  video_conferencing: VideoConferencing,
  hdmi: HDMI,
  auditorium: Auditorium,
  showers: Showers,
  bar: Bar,
  sofa_lounge: SofaLounge,
  bicycle_room: BicycleRoom,
  teracce: Terrace,
  cafe: Cafe,
  kitchen: Kitchen,
  IT: BoundingBox,
  App: GridView,
  Cleaning: CleaningBucket,
  Billing: CreditCard,
  Maintenance: Engineering,
  Other: SmartToy,
  Sales,
};

export const UNAUTHORIZED = 401;

export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const ROOM_TYPES_BY_ID = { meeting_room: 'Meeting Room', event_space: 'Event Space' };

export const EVENTS_IMAGES = [Events, Events_1, Events_2, Events_3, Events_4];

export const EVENTS_IMAGES_BY_NAME = {
  Wellness: FitnessClasses,
  Fun,
  'Give Back': GiveBack,
  Professional,
  Lifestyle,
};
