import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { RoomDetails as View } from '@views';
import { SUCCESS, LOADING, FAILURE } from '@constants/requestPhase';
import { BOOKING_DEFAULT_TITLE } from '@constants/common';
import { routes } from '@constants';
import { withNetwork } from '@hocs';
import { dayjs } from '@utils';
import { useDebounce } from '@hooks';
import { getTimeList, getAvailableTimelines } from '@helpers/time';
import { getMyCompanyAvailableCredits, getCompanyManagers } from '@store/company/duck';
import {
  getMeetingRoomById,
  getMeetingRoomBookingsById,
  resetPhases as resetRoomPhases,
  getBookingCost,
} from '@store/room/duck';
import { bookRoom, resetPhases as resetBookingPhases } from '@store/booking/duck';
import { trackEvent } from '@utils/mixpanel';

const RoomDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { user } = useSelector(store => store.userStore);

  const isAbleToPurchase = user.isAbleToInvoice || user.isAbleToPayByCard;

  const { roomById, getMeetingRoomByIdPhase, getMeetingRoomBookingsByIdPhase } = useSelector(store => store.roomStore);

  const { bookingById, bookRoomPhase, bookRoomError } = useSelector(store => store.bookingStore);

  const { getCompanyManagersPhase } = useSelector(store => store.companyStore);

  const [error, setError] = useState({});
  const [isBookingProcessing, setIsBookingProcessing] = useState(false);
  const [date, setDate] = useState(
    location.state?.date
      ? dayjs()
          .year(location.state.year)
          .month(location.state.month)
          .date(location.state.date)
          .add(dayjs().$u ? new Date().getTimezoneOffset() : 0, 'minute')
      : dayjs().add(dayjs().$u ? new Date().getTimezoneOffset() : 0, 'minute'),
  );
  const [startTime, setStartTime] = useState(location.state?.startTime ?? -1);
  const [endTime, setEndTime] = useState(location.state?.endTime ?? -1);
  const [title, setTitle] = useState('');
  const [externalCompany, setExternalCompany] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({ id: 0, name: '' });

  const [isPayByCardOpen, setIsPayByCardOpen] = useState(false);

  const isEventSpace = roomById?.roomType === 'event_space';

  const timeList = useMemo(() => getTimeList(date, roomById.location?.timeZone), [date, roomById.location?.timeZone]);

  const isToday = date.$d.toString().substring(0, 15) === dayjs().$d.toString().substring(0, 15);

  const maxBookingHours = roomById.maxBookingMinutes ? roomById.maxBookingMinutes / 60 : 24;
  const minBookingHours = roomById.minBookingMinutes ? roomById.minBookingMinutes / 60 : 0.5;

  const timelinesList = useMemo(
    () =>
      isToday
        ? timeList.filter(timeline => {
            const time = timeline.$d;

            const now = dayjs()
              .tz(roomById.location?.timeZone)
              .minute(dayjs().minute() - 10)
              .second(0)
              .millisecond(0).$d;

            return time >= now;
          })
        : timeList,
    [isToday, timeList, roomById.location?.timeZone],
  );

  const availableTimes = useMemo(
    () => getAvailableTimelines(timelinesList, roomById.bookings || [], roomById?.location?.timeZone),
    [timelinesList, roomById.bookings, roomById?.location?.timeZone],
  );

  const debouncedTime = useDebounce([startTime, endTime], 300);

  const handlePayByCardChangeState = useCallback(() => {
    setIsPayByCardOpen(prevState => !prevState);
  }, []);

  const handleBackClick = useCallback(() => {
    if (location.state?.filters) {
      history.push({
        pathname: routes.rooms,
        state: {
          filters: location.state.filters,
        },
      });
    } else {
      history.goBack();
    }
  }, [location.state?.filters]);

  const handleBuyCreditsClick = useCallback(() => {
    history.push({
      pathname: routes.creditsPackages,
      state: {
        from: window.location.pathname,
        data: {
          date: date.$D,
          month: date.$M,
          year: date.$y,
          startTime,
          endTime,
        },
      },
    });
  }, [date, startTime, endTime]);

  const handleTitleChange = useCallback(e => {
    setTitle(e.target.value);
  }, []);

  const handleCompanySelect = useCallback(company => {
    setSelectedCompany(company);
  }, []);

  const handleExternalCompanySelect = useCallback(company => {
    setExternalCompany(company);
  }, []);

  const handleTimelineClick = time => () => {
    if (time !== startTime) {
      const hours = isEventSpace ? minBookingHours : 1;
      const isPrevTimelineAvailable = availableTimes.includes(timeList[time - hours]);

      const isNextTimelineAvailable = availableTimes.includes(timeList[time + hours]);

      if (isPrevTimelineAvailable) {
        setStartTime(time - hours);
        setEndTime(time + hours);
      }
      if (isNextTimelineAvailable) {
        setStartTime(time);
        setEndTime(time + hours * 2);
      }
      if (
        !isPrevTimelineAvailable &&
        !isNextTimelineAvailable &&
        !user.isActivate &&
        (!isEventSpace || minBookingHours < 1)
      ) {
        setStartTime(time);
        setEndTime(time + 1);
      }
    }
  };

  const handleTimelineChange = useCallback(
    time => {
      const timeDifference = time[1] - time[0];

      if (
        isEventSpace
          ? timeDifference >= minBookingHours * 2 && timeDifference <= maxBookingHours * 2
          : timeDifference > (user.isActivate ? 1 : 0)
      ) {
        if (timeList.slice(time[0], time[1]).every(timeline => availableTimes.includes(timeline))) {
          setStartTime(time[0]);
          setEndTime(time[1]);
        }
      }
    },
    [timeList, availableTimes],
  );

  const handleBookRoom = useCallback(() => {
    setIsBookingProcessing(true);

    dispatch(
      bookRoom({
        ...(selectedCompany.id && { companyId: selectedCompany.id }),
        ...(externalCompany && { externalCompanyName: externalCompany }),
        meetingRoomId: parseInt(id, 10),
        title: title || BOOKING_DEFAULT_TITLE,
        fromDateTime: timeList[startTime].toISOString(),
        toDateTime: timeList[endTime] ? timeList[endTime].toISOString() : timeList[0].add(1, 'day').toISOString(),
        payBy: 'credits',
      }),
    );
  }, [timeList, title, endTime, startTime, selectedCompany, id, externalCompany]);

  useEffect(() => {
    if (bookRoomPhase === SUCCESS && bookingById.id && !bookingById.paymentInfo?.intentSecret) {
      dispatch(resetBookingPhases());
      dispatch(resetRoomPhases());
      dispatch(getMyCompanyAvailableCredits());
      history.push(`${routes.bookedRooms}/${bookingById.id}`);
      setIsBookingProcessing(false);
    }
    if (bookRoomPhase === FAILURE) {
      if (!isPayByCardOpen) {
        setError({
          status: bookRoomError.data.error.status,
          message: bookRoomError.data.error.message,
        });
      }
      setIsBookingProcessing(false);
      dispatch(resetBookingPhases());
    }
  }, [bookRoomPhase]);

  useEffect(() => {
    if (getMeetingRoomByIdPhase === FAILURE) {
      dispatch(resetRoomPhases());
      history.goBack();
    }
    if (getMeetingRoomByIdPhase === SUCCESS) {
      const pageTitle = `Mindspace: Room ${roomById.roomNumber}`;
      document.title = pageTitle;

      window.gtag('event', 'page_view', {
        page_title: pageTitle,
      });

      trackEvent('Page Viewed', {
        Page: 'Book a Room',
      });
    }
  }, [getMeetingRoomByIdPhase]);

  useEffect(() => {
    if (roomById.id !== parseInt(id, 10)) {
      dispatch(getMeetingRoomById(id));
    }
  }, [id]);

  useEffect(() => {
    if (user.id) {
      if (!user.isAbleToBookMeetingRoom) {
        history.goBack();
      } else if (!isAbleToPurchase) {
        dispatch(getCompanyManagers(user.company.id));
      }
    }
  }, [user]);

  useEffect(() => {
    if (startTime >= 0 && endTime > 0) {
      dispatch(
        getBookingCost({
          from: timeList[startTime].toISOString(),
          to: timeList[endTime] ? timeList[endTime].toISOString() : timeList[0]?.add(1, 'day').toISOString(),
          meetingRoomId: parseInt(id, 10),
          ...(selectedCompany.id && { companyId: selectedCompany.id }),
        }),
      );
    } else {
      dispatch(
        getBookingCost({
          from: timeList[0]?.toISOString(),
          to: timeList[0]?.toISOString(),
          meetingRoomId: parseInt(id, 10),
          ...(selectedCompany.id && { companyId: selectedCompany.id }),
        }),
      );
    }
  }, [debouncedTime, selectedCompany.id, date]);

  useEffect(() => {
    dispatch(getMeetingRoomBookingsById(id, timeList[0]?.toISOString()));
  }, [timeList[0], id]);

  const handleDateChange = useCallback(
    newDate => {
      const offset = dayjs(newDate).$offset === undefined ? 0 : dayjs(newDate).$offset;

      setDate(
        dayjs(newDate)
          .add(-(offset + new Date(newDate).getTimezoneOffset()), 'minutes')
          .add(dayjs(newDate).$u ? -new Date(newDate).getTimezoneOffset() : 0, 'minute'),
      );
    },
    [dayjs().$u, dayjs().$offset, new Date().getTimezoneOffset()],
  );

  const handleEndTimeChange = newEndTime => {
    setEndTime(newEndTime);
  };

  const handleStartTimeChange = newStartTime => {
    setStartTime(newStartTime);
  };

  const isLoadingState =
    getMeetingRoomByIdPhase === LOADING || getCompanyManagersPhase === LOADING || isBookingProcessing;

  return (
    <View
      creditPrices={roomById?.prices}
      maxBookingHours={maxBookingHours}
      minBookingHours={minBookingHours}
      externalCompany={externalCompany}
      onExternalCompanySelect={handleExternalCompanySelect}
      isEventSpace={isEventSpace}
      bookings={roomById.bookings}
      onPayByCardChangeState={handlePayByCardChangeState}
      onBuyCreditsClick={handleBuyCreditsClick}
      onCompanySelect={handleCompanySelect}
      selectedCompany={selectedCompany}
      error={error}
      areBookingsLoading={getMeetingRoomBookingsByIdPhase === LOADING}
      onSubmit={handleBookRoom}
      times={timeList}
      onDateChange={handleDateChange}
      onTimelineClick={handleTimelineClick}
      onTimelineChange={handleTimelineChange}
      onEndTimeChange={handleEndTimeChange}
      onStartTimeChange={handleStartTimeChange}
      date={date}
      endTime={endTime}
      startTime={startTime}
      isLoading={isLoadingState}
      roomNumber={roomById.roomNumber}
      seats={roomById.workstationCount}
      floor={roomById.floor}
      creditsPrice={roomById.creditsPerHour}
      currencyPrice={roomById.pricePerHour}
      features={roomById.features}
      availableTimes={availableTimes}
      title={title}
      onTitleChange={handleTitleChange}
      roomPhotos={roomById.gallery}
      companyName={user.company?.name}
      onBackClick={handleBackClick}
      isPayByCardOpen={isPayByCardOpen}
    />
  );
};

export default withNetwork(RoomDetails);
