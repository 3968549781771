import React from 'react';
import { bool, func } from 'prop-types';

import { Flex } from '@mixins';
import { theme } from '@styles';

import { Container } from './styles';

const Switcher = ({ isEnabled, onSwitch, ...props }) => (
  <Flex
    {...props}
    position="relative"
    pointer
    onClick={onSwitch}
    background={isEnabled ? theme.color.darkGreen : theme.color.gray[100]}
    alignItems="center"
    width={[26, 30, 30]}
    height={[10, 12, 14]}
    borderRadius="7px">
    <Container
      position="absolute"
      left={isEnabled ? ['17px', '19px', '17px'] : '1px'}
      background={theme.color.white}
      borderRadius="50%"
      width={['8px', '10px', '12px']}
      height={['8px', '10px', '12px']}
    />
  </Flex>
);

Switcher.propTypes = {
  isEnabled: bool.isRequired,
  onSwitch: func.isRequired,
};

export default Switcher;
