import React, { useMemo } from 'react';
import { bool, func, number, string } from 'prop-types';
import { useSelector } from 'react-redux';

import { Button } from '@mixins';
import { getCurrency } from '@helpers/common';

import {
  COMMUNITY_MANAGER_ROLE,
  ADMIN_ROLE,
  EMPLOYEE_ROLE,
  CMS_EMPLOYEE_ROLE,
  EMPLOYEE_CM_ROLE,
} from '@constants/common';

const ManageBookingButtons = ({
  isSelectedTimelineAvailable,
  isCompanySelected,
  isEnoughCredits,
  bookingHours,
  isEditing,
  onSubmit,
  onBuyCreditsClick,
  onPayNowClick,
  paymentType,
  money,
  credits,
  isEventSpace,
}) => {
  const { user } = useSelector(store => store.userStore);

  const { roomById } = useSelector(store => store.roomStore);

  const { currentLocation } = useSelector(store => store.locationStore);

  const isAbleToPurchase = user.isAbleToInvoice || user.isAbleToPayByCard;
  const isAdmin =
    user.role === COMMUNITY_MANAGER_ROLE ||
    user.role === ADMIN_ROLE ||
    user.role === EMPLOYEE_CM_ROLE ||
    user.role === EMPLOYEE_ROLE ||
    user.role === CMS_EMPLOYEE_ROLE ||
    !currentLocation.isMindspace;

  const actionButtonLabel = useMemo(() => {
    if (isEditing) {
      if (paymentType === 'money') {
        const diffMoneySign = Math.sign(roomById.cost - money);
        switch (diffMoneySign) {
          case 1:
            return `Pay ${getCurrency(currentLocation.currency, roomById.cost - money)} Now and Update`;
          case -1:
            // eslint-disable-next-line max-len
            return `Update and Receive ${getCurrency(currentLocation.currency, money - roomById.cost)} Refund`;
          default:
            return 'Update';
        }
      }

      if (!isEnoughCredits) {
        return currentLocation.isWhiteLabeled ? 'Buy Company Credits to Update' : 'Buy Credits';
      }

      const diffCreditsSign = Math.sign(roomById.creditsCost - credits);
      switch (diffCreditsSign) {
        case 1:
          return currentLocation.isWhiteLabeled ? 'Update using company credits' : 'Update';
        case -1:
          return currentLocation.isWhiteLabeled
            ? `Update and Receive ${credits - roomById.creditsCost} ${
                credits - roomById.creditsCost > 1 ? 'Credits' : 'Credit'
              } Refund`
            : 'Update';
        default:
          return 'Update';
      }
    }

    return `Book for ${bookingHours} ${bookingHours > 1 ? 'hours' : 'hour'}`;
  }, [
    isEditing,
    paymentType,
    bookingHours,
    currentLocation.currency,
    roomById.cost,
    isEnoughCredits,
    currentLocation.isWhiteLabeled,
  ]);

  switch (true) {
    case isAdmin:
      return (
        <Button
          mt={10}
          py={['8px', 10, 12]}
          width="100%"
          fontSize={[12, 14, 16]}
          onClick={onSubmit}
          disabled={!isSelectedTimelineAvailable || (isCompanySelected && !isEnoughCredits)}
          variant="primary">
          {isEditing ? 'Update' : `Book for ${bookingHours} ${bookingHours > 1 ? 'hours' : 'hour'}`}
        </Button>
      );
    case roomById.location?.isWhiteLabeled && !isEditing:
      return (
        <>
          <Button
            mt={10}
            py={['8px', 10, 12]}
            width="100%"
            fontSize={[12, 14, 16]}
            onClick={isEnoughCredits ? onSubmit : onBuyCreditsClick}
            disabled={!isSelectedTimelineAvailable && isEnoughCredits}
            variant="primary">
            {!isEnoughCredits
              ? 'Buy Company Credits'
              : `Book for ${bookingHours} ${bookingHours > 1 ? 'hours' : 'hour'}`}
          </Button>
          {!isEventSpace && (
            <Button
              mt={10}
              py={['8px', 10, 12]}
              width="100%"
              fontSize={[12, 14, 16]}
              backgroundColor="#EFECE6"
              disabled={!isSelectedTimelineAvailable}
              onClick={onPayNowClick}>
              {`Pay ${getCurrency(currentLocation.currency, roomById.cost)} Now and Book`}
            </Button>
          )}
        </>
      );
    case !isAbleToPurchase:
      return (
        <Button
          mt={10}
          py={['8px', 10, 12]}
          width="100%"
          fontSize={[12, 14, 16]}
          disabled={!isEnoughCredits || !isSelectedTimelineAvailable}
          onClick={onSubmit}
          variant="primary">
          {actionButtonLabel}
        </Button>
      );
    default:
      return (
        <>
          {!isEnoughCredits && !isEditing && (
            <Button
              mt={10}
              py={['8px', 10, 12]}
              width="100%"
              fontSize={[12, 14, 16]}
              onClick={onBuyCreditsClick}
              variant="primary">
              Buy credits
            </Button>
          )}
          <Button
            mt={10}
            variant="primary"
            py={['8px', 10, 12]}
            width="100%"
            fontSize={[12, 14, 16]}
            onClick={!isEnoughCredits && paymentType === 'credits' ? onBuyCreditsClick : onSubmit}
            disabled={
              (!isEditing && (!isEnoughCredits || !isSelectedTimelineAvailable)) ||
              (isEditing && !isSelectedTimelineAvailable)
            }>
            {actionButtonLabel}
          </Button>
        </>
      );
  }
};

ManageBookingButtons.propTypes = {
  isEventSpace: bool,
  onBuyCreditsClick: func.isRequired,
  onPayNowClick: func.isRequired,
  isSelectedTimelineAvailable: bool.isRequired,
  isCompanySelected: bool.isRequired,
  isEnoughCredits: bool.isRequired,
  bookingHours: number.isRequired,
  isEditing: bool.isRequired,
  onSubmit: func.isRequired,
  paymentType: string,
  money: number,
  credits: number,
};

ManageBookingButtons.defaultProps = {
  isEventSpace: false,
  paymentType: 'credits',
  money: 0,
  credits: 0,
};

export default ManageBookingButtons;
