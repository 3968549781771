import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { string, number, arrayOf, func, shape, bool, exact } from 'prop-types';

import { Icon, Timeline, Loader } from '@atoms';
import { TextInput } from '@atoms/Input';
import { Flex, Typography } from '@mixins';
import { dayjs } from '@utils';
import { theme } from '@styles';
import { DateSelectingModal, TimeSelectingModal } from '@molecules';
import { formatTime, getDateLabel, getClosestTime } from '@helpers/time';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

const RoomBookingForm = ({
  maxBookingHours,
  isEventSpace,
  bookings,
  timeZone,
  timeFormat,
  areBookingsLoading,
  times,
  onDateChange,
  onEndTimeChange,
  onStartTimeChange,
  date,
  onTimelineChange,
  onTimelineClick,
  availableTimes,
  endTime,
  startTime,
  isLoading,
  title,
  onTitleChange,
}) => {
  const { user } = useSelector(store => store.userStore);

  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);

  const isToday = dayjs().$D === date.$D;

  const closestTime = getClosestTime();

  const timelineScrollToIndex = times.findIndex(time => formatTime(time, '24h') === formatTime(closestTime, '24h'));

  const handleDateModalChangeState = useCallback(() => {
    setIsDateModalOpen(prevValue => {
      if (!prevValue) {
        setIsTimeModalOpen(false);
      }

      return !prevValue;
    });
  }, []);

  const handleTimeModalChangeState = useCallback(() => {
    setIsTimeModalOpen(prevValue => {
      if (!prevValue) {
        setIsDateModalOpen(false);
      }

      return !prevValue;
    });
  }, []);

  const handleTimeSelect = (newStartTime, newEndTime) => () => {
    const startTimeIndex = times.indexOf(newStartTime);
    const endTimeIndex = times.indexOf(newEndTime);
    if (times.slice(startTimeIndex, endTimeIndex).every(timeline => availableTimes.includes(timeline))) {
      onStartTimeChange(times.indexOf(newStartTime));
      onEndTimeChange(times.indexOf(newEndTime) || 48);
      handleTimeModalChangeState();
    }
  };

  const handleDateSelect = newDate => {
    onDateChange(newDate);
    handleDateModalChangeState();
  };

  const getScrollToValue = () => {
    if (startTime >= 0) {
      return startTime;
    }
    if (isToday) {
      return timelineScrollToIndex;
    }
    return 20;
  };

  return (
    <>
      <Typography mt={20} mb={15} variant="garamond-500" fontSize={[18, 20, 22, 24]}>
        When
      </Typography>
      <Flex
        border="1px solid #EFECE6"
        boxShadow="0px 8px 30px rgba(80, 85, 136, 0.06)"
        p={10}
        borderRadius="2px"
        width="100%"
        position="relative"
        flexDirection="column">
        <Timeline
          userRole={user?.role}
          bookings={bookings}
          timeZone={timeZone}
          timeFormat={timeFormat}
          timeList={times}
          scrollTo={getScrollToValue()}
          isLoading={areBookingsLoading}
          onTimeChange={onTimelineChange}
          onTimeClick={onTimelineClick}
          availableTimes={availableTimes}
          startTime={startTime}
          endTime={endTime}
        />
        {areBookingsLoading && <Loader position="absolute" background={theme.color.white} width={40} height={40} />}
      </Flex>
      <Flex mt={25} mb={20} alignItems="baseline">
        <Typography mr={25} variant="garamond-500" fontSize={[10, 14, 18]}>
          Date
        </Typography>
        <Flex position="relative">
          <Flex pointer onClick={handleDateModalChangeState} pr={['8px', '8px', 10, 16]} alignItems="center">
            <Typography
              variant="proximaNova-400"
              color={date ? theme.color.darkGreen : theme.color.gray[500]}
              fontSize={[10, 12, 14]}>
              {getDateLabel(date, timeZone)}
            </Typography>
            <Icon
              ml={['4px', '4px', '8px']}
              SVG={Dropdown}
              width={[6, 6, 10, 14]}
              color={date ? theme.color.darkGreen : theme.color.gray[500]}
            />
          </Flex>
          {isDateModalOpen && !isLoading && (
            <DateSelectingModal
              hasSelectingButton={false}
              canBeClosed={false}
              onSelect={handleDateSelect}
              calendarDate={date.$d}
              type="month"
            />
          )}
        </Flex>
      </Flex>
      <Flex alignItems="baseline">
        <Typography mr={25} variant="garamond-500" fontSize={[10, 14, 18]}>
          Time
        </Typography>
        <Flex position="relative">
          <Flex pointer onClick={handleTimeModalChangeState} alignItems="center">
            <Typography
              variant="proximaNova-400"
              color={startTime === -1 && endTime === -1 ? theme.color.gray[500] : theme.color.darkGreen}
              fontSize={[10, 12, 14]}>
              {startTime === -1 && endTime === -1
                ? 'Time & Durarion'
                : `${formatTime(times[startTime], timeFormat, 'title', timeZone)} - ${formatTime(
                    endTime === 48 ? times[0] : times[endTime],
                    timeFormat,
                    'title',
                    timeZone,
                  )}`}
            </Typography>
            <Icon ml={['4px', '4px', '8px']} SVG={Dropdown} width={[6, 6, 10, 14]} color={theme.color.darkGreen} />
          </Flex>
          {isTimeModalOpen && !isLoading && (
            <TimeSelectingModal
              maxBookingHours={maxBookingHours}
              isEventSpace={isEventSpace}
              timeZone={timeZone}
              timeFormat={timeFormat}
              scrollTo={getScrollToValue()}
              canBeClosed={false}
              onSelect={handleTimeSelect}
              endTime={endTime === 48 ? times[0] : times[endTime]}
              startTime={times[startTime]}
              times={times}
              availableTimes={availableTimes}
            />
          )}
        </Flex>
      </Flex>
      <Flex flexDirection="column" maxWidth={[200, 400]}>
        <Typography pt={30} mb={10} fontSize={[14, 16, 18, 20]} variant="garamond-500">
          Title
        </Typography>
        <TextInput value={title} onChange={onTitleChange} placeholder="Every meeting starts with a great title" />
      </Flex>
    </>
  );
};

RoomBookingForm.defaultProps = {
  isEventSpace: false,
  bookings: [],
  availableTimes: [],
  maxBookingHours: 3,
};

RoomBookingForm.propTypes = {
  maxBookingHours: number,
  isEventSpace: bool,
  bookings: arrayOf(
    exact({
      fromDateTime: string,
      toDateTime: string,
      company: string,
    }),
  ),
  timeZone: string.isRequired,
  timeFormat: string.isRequired,
  areBookingsLoading: bool.isRequired,
  title: string.isRequired,
  onTitleChange: func.isRequired,
  onTimelineChange: func.isRequired,
  onTimelineClick: func.isRequired,
  times: arrayOf(shape({})).isRequired,
  availableTimes: arrayOf(shape({})),
  isLoading: bool.isRequired,
  onDateChange: func.isRequired,
  onStartTimeChange: func.isRequired,
  onEndTimeChange: func.isRequired,
  startTime: number.isRequired,
  endTime: number.isRequired,
  date: shape({}).isRequired,
};

export default RoomBookingForm;
