import React from 'react';
import styled, { css } from 'styled-components';
import { space, flexbox, layout, position } from 'styled-system';
import { bool, func } from 'prop-types';

import { Icon } from '@atoms';
import { Flex } from '@mixins';
import { theme } from '@styles';

import { ReactComponent as CheckboxIcon } from '@assets/svgs/Checkbox.svg';

const Checkbox = ({ isEnabled, onClick, isBlocked }) => {
  const getBackgroundColor = () => {
    if (isBlocked) {
      return theme.color.gray[100];
    }
    if (isEnabled) {
      return theme.color.darkGreen;
    }
    return theme.color.white;
  };

  return (
    <Flex
      position="relative"
      pointer={!isBlocked}
      {...(!isBlocked && { onClick })}
      background={getBackgroundColor()}
      alignItems="center"
      justifyContent="center"
      width={[14, 16, 18]}
      minHeight={[14, 16, 18]}
      border={`1px solid ${isBlocked ? theme.color.gray[100] : theme.color.darkGreen}`}
    >
      <Icon SVG={CheckboxIcon} />
    </Flex>
  );
};

Checkbox.propTypes = {
  isBlocked: bool,
  isEnabled: bool.isRequired,
  onClick: func.isRequired,
};

Checkbox.defaultProps = {
  isBlocked: false,
};

export default styled(Checkbox)`
  ${() => css`
    ${space};
    ${flexbox};
    ${layout};
    ${position};
  `}
`;
