import React from 'react';
import { string, arrayOf, exact, bool, shape } from 'prop-types';

import { Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { ReactComponent as Email } from '@assets/svgs/Email.svg';

import { SocialBlock } from './styles';

const getColor = (isDisabled, isCompany) => {
  switch (true) {
    case isDisabled:
      return theme.color.gray[200];
    case isCompany:
      return theme.color.darkRed;
    default:
      return theme.color.darkGreen;
  }
};

const getLabel = (isCompany, isEditable, name) => {
  switch (true) {
    case isCompany:
      return `Company ${name} Profile`;
    case isEditable:
      return `${name} Profile`;
    default:
      return name;
  }
};

const Socials = ({ socials, email, isCompany, isEditable }) => {
  const handler = link => () => {
    const socialLink = link.includes('https://') ? link : `https://${link}`;
    window.open(socialLink);
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`;
  };

  const socialsBlock = socials.reduce((acc, { name, icon, link }, index) => {
    if (link || isEditable) {
      acc.push(
        <SocialBlock
          key={name}
          pb={isCompany ? 0 : [10, 16, 20]}
          mb={isCompany ? [10, 16, 20] : 0}
          mr={[10, 15, 20]}
          pt={isCompany ? '5px' : [10, 16, 20]}
          width={isCompany && !isEditable ? 'fit-content' : '100%'}
          flexDirection={isCompany ? 'row-reverse' : 'row'}
          justifyContent={isCompany ? 'start' : 'space-between'}
          borderTop={isCompany || !index ? 'none' : `1px solid ${theme.color.gray[100]}`}
          alignItems="center"
          pointer={link}
          {...(link && { onClick: handler(link) })}
          disabled={!link}
          isCompany={isCompany}>
          {(isEditable || !isCompany) && (
            <Typography ml={isCompany ? 15 : 0} fontSize={[10, 14, 16]} variant="proximaNova-400">
              {getLabel(isCompany, isEditable, name)}
            </Typography>
          )}
          <Icon color={getColor(!link, isCompany)} SVG={icon} width={[14, 20, 26]} />
        </SocialBlock>,
      );
    }
    return acc;
  }, []);

  return (
    <Flex width="100%" flexDirection={isCompany && !isEditable ? 'row' : 'column'}>
      {!isCompany && email && (
        <Flex
          pb={[10, 16, 20]}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          pointer
          onClick={handleEmailClick}>
          <Typography fontSize={[10, 14, 16]} variant="proximaNova-400" color={theme.color.darkGreen}>
            {email}
          </Typography>
          <Icon SVG={Email} width={[14, 20, 26]} />
        </Flex>
      )}
      {socialsBlock}
    </Flex>
  );
};

Socials.defaultProps = {
  socials: [],
  email: '',
};

Socials.propTypes = {
  isEditable: bool.isRequired,
  email: string,
  socials: arrayOf(
    exact({
      name: string,
      link: string,
      icon: shape({}),
    }),
  ),
  isCompany: bool.isRequired,
};

export default Socials;
