import React, { useState, useMemo, useCallback } from 'react';
import { bool, number, func, shape, arrayOf, string } from 'prop-types';
import { useSelector } from 'react-redux';

import { Icon } from '@atoms';
import { DateSelectingModal, TimeSelectingModal, ListSelectingModal } from '@molecules';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { dayjs } from '@utils';
import { getSuffixByNumericValue } from '@helpers/common';
import { ROOM_TYPES_BY_ID } from '@constants/common';
import { formatTime, getDateLabel, getClosestTime, getAvailableTimelines } from '@helpers/time';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import VideoConferencing from '@assets/svgs/VideoConferencing.svg';
import Checkmark from '@assets/svgs/Checkmark.svg';
import { trackEvent } from '@utils/mixpanel';

const RoomsFilter = ({
  onTypeChange,
  type,
  timeList,
  isLoading,
  onDateChange,
  onStartTimeChange,
  onEndTimeChange,
  onFloorChange,
  onSeatsChange,
  seats,
  startTime,
  endTime,
  floor,
  date,
  selectedCustomFilters,
  onCustomFilterClick,
}) => {
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
  const [isFloorModalOpen, setIsFloorModalOpen] = useState(false);
  const [isSeatsModalOpen, setIsSeatsModalOpen] = useState(false);
  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);

  const isToday = date.$d.toString().substring(0, 15) === dayjs().$d.toString().substring(0, 15);

  const closestTime = getClosestTime();

  const timeScrollToIndex = timeList.findIndex(time => formatTime(time, '24h') === formatTime(closestTime, '24h'));

  const timelinesList = isToday
    ? timeList.filter(timeline => {
        const time = timeline.$d;

        const now = dayjs()
          .minute(dayjs().minute() - 10)
          .second(0)
          .millisecond(0).$d;

        return time >= now;
      })
    : timeList;

  const availableTimes = useMemo(() => getAvailableTimelines(timelinesList, []), [timelinesList]);

  const {
    rooms: { availableOptions },
  } = useSelector(store => store.roomStore);

  const { currentLocation } = useSelector(store => store.locationStore);

  const handleDateModalChangeState = useCallback(() => {
    setIsDateModalOpen(prevValue => {
      if (!prevValue) {
        setIsTypeModalOpen(false);
        setIsTimeModalOpen(false);
        setIsFloorModalOpen(false);
        setIsSeatsModalOpen(false);
      }

      return !prevValue;
    });
  }, []);

  const handleSeatsModalChangeState = useCallback(() => {
    setIsSeatsModalOpen(prevValue => {
      if (!prevValue) {
        setIsTypeModalOpen(false);
        setIsTimeModalOpen(false);
        setIsFloorModalOpen(false);
        setIsDateModalOpen(false);
      }

      return !prevValue;
    });
  }, []);

  const handleTypeModalChangeState = useCallback(() => {
    setIsTypeModalOpen(prevValue => {
      if (!prevValue) {
        setIsSeatsModalOpen(false);
        setIsTimeModalOpen(false);
        setIsFloorModalOpen(false);
        setIsDateModalOpen(false);
      }

      return !prevValue;
    });
  }, []);

  const handleTimeModalChangeState = useCallback(() => {
    setIsTimeModalOpen(prevValue => {
      if (!prevValue) {
        setIsTypeModalOpen(false);
        setIsDateModalOpen(false);
        setIsFloorModalOpen(false);
        setIsSeatsModalOpen(false);
      }

      return !prevValue;
    });
  }, []);

  const handleFloorModalChangeState = useCallback(() => {
    setIsFloorModalOpen(prevValue => {
      if (!prevValue) {
        setIsTypeModalOpen(false);
        setIsDateModalOpen(false);
        setIsTimeModalOpen(false);
        setIsSeatsModalOpen(false);
      }

      return !prevValue;
    });
  }, []);

  const handleDateModalClose = useCallback(() => {
    trackEvent('Room Filter Opened', {
      scope: 'Rooms',
      Filter: 'Date',
    });

    setIsDateModalOpen(false);
    onDateChange(dayjs());
  }, []);

  const handleTimeModalClose = useCallback(() => {
    trackEvent('Room Filter Opened', {
      scope: 'Rooms',
      Filter: 'Time',
    });

    setIsTimeModalOpen(false);
    onStartTimeChange(null);
    onEndTimeChange(null);
  }, []);

  const handleFloorModalClose = useCallback(() => {
    trackEvent('Room Filter Opened', {
      scope: 'Rooms',
      Filter: 'Floor',
    });

    setIsFloorModalOpen(false);
    onFloorChange(null);
  }, []);

  const handleSeatsModalClose = useCallback(() => {
    trackEvent('Room Filter Opened', {
      scope: 'Rooms',
      Filter: 'Seats',
    });

    setIsSeatsModalOpen(false);
    onSeatsChange(null);
  }, []);

  const handleTypeModalClose = useCallback(() => {
    trackEvent('Room Filter Opened', {
      scope: 'Rooms',
      Filter: 'Room Type',
    });

    setIsTypeModalOpen(false);
    onTypeChange(null);
  }, []);

  const handleDateSelect = newDate => {
    onDateChange(newDate);
    handleDateModalChangeState();
  };

  const handleFloorSelect = newFloor => () => {
    onFloorChange(newFloor);
    handleFloorModalChangeState();
  };

  const handleSeatsSelect = newSeats => () => {
    onSeatsChange(newSeats);
    handleSeatsModalChangeState();
  };

  const handleTypeSelect = newType => () => {
    onTypeChange(newType);
    handleTypeModalChangeState();
  };

  const handleTimeSelect = (newStartTime, newEndTime) => () => {
    onStartTimeChange(newStartTime);
    onEndTimeChange(newEndTime);
    handleTimeModalChangeState();
  };

  const handleCustomFilterClick = id => () => {
    trackEvent('Room Filter Opened', {
      scope: 'Rooms',
      Filter: predefinedFeatures.find(feature => feature.id === id)?.displayName,
    });

    onCustomFilterClick(id);
  };

  const getSeatsOptions = () => {
    if (type === 'Meeting Room') {
      return availableOptions.meetingRoomWorkstationCount;
    }
    if (type === 'Event Space') {
      return availableOptions.eventSpaceWorkstationCount;
    }
    return availableOptions.workstationCount;
  };

  const getFloorLabel = () => {
    if (floor === null) {
      return 'Floor';
    }
    if (floor === 0) {
      return 'Floor 0';
    }
    return `${getSuffixByNumericValue(floor)} Floor`;
  };

  const predefinedFeatures = useMemo(
    () =>
      availableOptions?.features?.filter(feature => feature.key === 'video_conferencing' || feature.key === 'window'),
    [availableOptions?.features],
  );

  const roomTypes = useMemo(
    () => availableOptions?.roomType?.map(roomType => ROOM_TYPES_BY_ID[roomType]),
    [availableOptions?.roomType],
  );

  const filters = [
    {
      title: getDateLabel(date),
      modal: (
        <DateSelectingModal
          onSelect={handleDateSelect}
          onModalClose={handleDateModalClose}
          calendarDate={date.$d}
          hasSelectingButton={false}
          type="month"
        />
      ),
      isVisible: true,
      isModalOpen: isDateModalOpen,
      modalStateHandler: handleDateModalChangeState,
      isValueNotEmpty: date,
    },
    {
      title:
        endTime && startTime
          ? `${formatTime(
              startTime,
              currentLocation.timeFormat,
              'title',
            )}-${formatTime(endTime, currentLocation.timeFormat, 'title')}`
          : 'Time & Duration',
      modal: (
        <TimeSelectingModal
          onSelect={handleTimeSelect}
          onModalClose={handleTimeModalClose}
          endTime={endTime}
          times={timeList}
          startTime={startTime}
          availableTimes={availableTimes}
          scrollTo={isToday ? timeScrollToIndex : 20}
        />
      ),
      isVisible: true,
      isModalOpen: isTimeModalOpen,
      modalStateHandler: handleTimeModalChangeState,
      isValueNotEmpty: endTime && startTime,
    },
    {
      title: getFloorLabel(),
      modal: (
        <ListSelectingModal
          hasSelectingButton={false}
          onSelect={handleFloorSelect}
          onModalClose={handleFloorModalClose}
          selectedItem={floor}
          title="Floor"
          items={availableOptions?.floor}
        />
      ),
      isVisible: availableOptions?.floor?.length > 1,
      isModalOpen: isFloorModalOpen,
      modalStateHandler: handleFloorModalChangeState,
      isValueNotEmpty: floor !== null,
    },
    {
      title: `${seats ? `${seats} ` : ''}${seats === 1 ? 'Seat' : 'Seats'}`,
      modal: (
        <ListSelectingModal
          hasSelectingButton={false}
          onSelect={handleSeatsSelect}
          onModalClose={handleSeatsModalClose}
          selectedItem={seats}
          title="Seats"
          items={getSeatsOptions()}
        />
      ),
      isVisible: getSeatsOptions()?.length > 1,
      isModalOpen: isSeatsModalOpen,
      modalStateHandler: handleSeatsModalChangeState,
      isValueNotEmpty: seats,
    },
    {
      title: type ?? 'Room Type',
      modal: (
        <ListSelectingModal
          hasSelectingButton={false}
          onSelect={handleTypeSelect}
          onModalClose={handleTypeModalClose}
          selectedItem={type}
          title="Room Type"
          items={roomTypes}
        />
      ),
      isVisible: roomTypes?.length > 1,
      isModalOpen: isTypeModalOpen,
      modalStateHandler: handleTypeModalChangeState,
      isValueNotEmpty: type,
    },
  ];

  const modalsBlock = useMemo(
    () =>
      filters.map(
        ({ title, modal, isModalOpen, modalStateHandler, isValueNotEmpty, isVisible }, index) =>
          isVisible && (
            <Flex mb={10} key={title} position="relative">
              <Flex pr="5px" pl={index ? '5px' : 0} borderRight={`1px solid ${theme.color.gray[500]}`}>
                <Flex
                  {...(isModalOpen && { backgroundColor: '#EBEFEF' })}
                  pointer
                  onClick={modalStateHandler}
                  pr={['3px', '3px', '5px', 11]}
                  pl={index ? ['3px', '3px', '5px', 11] : 0}
                  alignItems="center">
                  <Typography
                    variant="proximaNova-400"
                    color={isValueNotEmpty ? theme.color.darkGreen : theme.color.gray[500]}
                    fontWeight={isValueNotEmpty ? '600' : '400'}
                    fontSize={[10, 14, 14, 16]}>
                    {title}
                  </Typography>
                  <Icon ml={['4px', '4px', '8px']} SVG={Dropdown} height={[6, 6, 10, 14]} color={theme.color.black} />
                </Flex>
              </Flex>
              {isModalOpen && !isLoading && modal}
            </Flex>
          ),
      ),
    [filters, type, availableOptions],
  );

  const customFiltersBlock = useMemo(
    () =>
      predefinedFeatures?.map(({ id, displayName, iconUrl }, index) => {
        const isFilterSelected = selectedCustomFilters.find(filterId => filterId === id);

        return (
          <Flex
            key={id}
            mb={10}
            pointer
            onClick={handleCustomFilterClick(id)}
            borderLeft={!index ? 'none' : `1px solid ${theme.color.gray[500]}`}
            px={['8px', '8px', 10, 16]}
            alignItems="center">
            <svg width="18" height="18">
              <image xlinkHref={isFilterSelected ? Checkmark : iconUrl || VideoConferencing} width="18" height="18" />
            </svg>
            <Typography
              ml={['4px', '4px', '8px']}
              variant="proximaNova-400"
              color={isFilterSelected ? theme.color.darkGreen : theme.color.gray[500]}
              fontWeight={isFilterSelected ? '600' : '400'}
              fontSize={[10, 12, 12, 14]}>
              {displayName}
            </Typography>
          </Flex>
        );
      }),
    [availableOptions, selectedCustomFilters, getSeatsOptions],
  );

  return (
    <Flex width="100%" alignItems="center" mb={[20, 30, 40]} flexWrap="wrap">
      {modalsBlock}
      {customFiltersBlock}
    </Flex>
  );
};

RoomsFilter.defaultProps = {
  type: null,
  selectedCustomFilters: [],
  timeList: [],
  startTime: {},
  endTime: {},
  floor: null,
  seats: null,
};

RoomsFilter.propTypes = {
  onTypeChange: func.isRequired,
  type: string,
  timeList: arrayOf(shape({})),
  onCustomFilterClick: func.isRequired,
  isLoading: bool.isRequired,
  onDateChange: func.isRequired,
  onStartTimeChange: func.isRequired,
  onEndTimeChange: func.isRequired,
  onFloorChange: func.isRequired,
  onSeatsChange: func.isRequired,
  seats: string,
  startTime: shape({}),
  endTime: shape({}),
  floor: number,
  date: shape({}).isRequired,
  selectedCustomFilters: arrayOf(number),
};

export default RoomsFilter;
