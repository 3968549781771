import React, { useState, useEffect } from 'react';
import { func, bool, exact, string, number } from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

import { ControlledInput, PhoneNumberInput } from '@atoms/Input';
import { Modal, Checkbox, Icon, Switcher, Loader, BootstrapTooltip } from '@atoms';
import { createCompanyMember, updateCompanyMember, resetPhases } from '@store/company/duck';
import { RadioButtonGroup, ManageCompanyMembersErrorModal } from '@molecules';
import { Typography, Button, Flex } from '@mixins';
import { schemas } from '@utils';
import { theme } from '@styles';
import { ReactComponent as Info } from '@assets/svgs/Info.svg';
import { ScrollableContainer } from '@molecules/ChangeCardModal/styles';
import { VALIDATION } from '@constants';
import COUNTRIES from '@constants/countries';
import { LOADING, SUCCESS } from '@constants/requestPhase';
import { permissions } from './purchasingPermissions';

const ManageCompanyMemberModal = ({ isOpen, onModalClose, user, accessId }) => {
  const {
    createCompanyMemberPhase,
    updateCompanyMemberPhase,
    createCompanyMemberError,
    updateCompanyMemberError,
    adminCompanyById,
  } = useSelector(store => store.companyStore);
  const {
    user: { id: userId },
  } = useSelector(store => store.userStore);

  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: async () => ({
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
    }),
    resolver: yupResolver(schemas.companyMember),
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = methods;

  const handleModalClose = () => {
    setHasPhoneError(false);
    reset({
      firstName: '',
      lastName: '',
      email: '',
    });
    setSelectedCountry('IL');
    setPhoneNumberValue('');
    setAdmin(false);
    setIsActive(true);
    setIsAbleToBookMeetingRoom(true);
    setPurchasingPermission(1);
    onModalClose();
  };

  const getPermission = () => {
    if (user.isAbleToInvoice && user.isAbleToPayByCard) {
      return 2;
    }
    if (user.isAbleToPayByCard) {
      return 1;
    }
    return 3;
  };

  const [hasPhoneError, setHasPhoneError] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isAbleToBookMeetingRoom, setIsAbleToBookMeetingRoom] = useState(true);
  const [purchasingPermission, setPurchasingPermission] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState('IL');
  const [phoneNumberValue, setPhoneNumberValue] = useState('');

  useEffect(() => {
    if (user) {
      if (user.phone) {
        const parsedPhoneNumber = parsePhoneNumber(user.phone);
        setPhoneNumberValue(parsedPhoneNumber.nationalNumber);
        setSelectedCountry(parsedPhoneNumber.country);
      }
      reset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      });
      setAdmin(user.isCompanyAdmin);
      setIsActive(user.status === 'active');
      setIsAbleToBookMeetingRoom(user.isAbleToBookMeetingRoom);
      setPurchasingPermission(getPermission());
    }
  }, [user]);

  useEffect(() => {
    if (createCompanyMemberPhase === SUCCESS || updateCompanyMemberPhase === SUCCESS) {
      handleResetPhase();
      handleModalClose();
    }
  }, [createCompanyMemberPhase, updateCompanyMemberPhase]);

  const handleCountrySelect = countryCode => {
    setSelectedCountry(countryCode);
  };

  const handleAdminChange = () => {
    setAdmin(prevState => !prevState);
  };

  const handleActiveChangeState = () => {
    setIsActive(prevState => !prevState);
  };

  const handleBookingPermissionChange = () => {
    setIsAbleToBookMeetingRoom(prevState => !prevState);
  };

  const handlePurchasingPermissionChange = id => () => {
    setPurchasingPermission(id);
  };

  const handlePhoneNumberChange = phoneNumber => {
    setPhoneNumberValue(phoneNumber);
  };

  const handleResetPhase = () => {
    dispatch(resetPhases());
  };

  const onSubmit = async formData => {
    if (!phoneNumberValue || isValidPhoneNumber(phoneNumberValue, selectedCountry)) {
      const form = {
        ...formData,
        ...(phoneNumberValue.trim() && {
          phoneCode: COUNTRIES[selectedCountry]?.dialCode.slice(1),
          phoneNumber: phoneNumberValue.replaceAll(' ', ''),
        }),
        status: isActive && adminCompanyById?.status === 'active' ? 'active' : 'inactive',
        isCompanyAdmin: isAdmin,
        isAbleToBookMeetingRoom,
        ...permissions[purchasingPermission - 1].values,
      };
      if (user) {
        dispatch(updateCompanyMember(user.id, form, accessId));
      } else {
        dispatch(createCompanyMember(form, accessId));
      }
    } else {
      setHasPhoneError(true);
    }
  };

  return (
    <Modal onClose={handleModalClose} isOpen={isOpen}>
      <FormProvider {...methods}>
        <Flex flexGrow={1} height={[500]} width={[600]} position="relative" flexDirection="column">
          <Typography mb={20} variant="proximaNova-600" fontSize={[16, 18, 20]}>
            {user ? 'Edit' : 'Add'} Team Member
          </Typography>
          {createCompanyMemberPhase === LOADING || updateCompanyMemberPhase === LOADING ? (
            <Flex justifyContent="center" flexGrow={1}>
              <Loader background="white" width={40} height={40} />
            </Flex>
          ) : (
            <ScrollableContainer overflowY="scroll" pr="6px" flexDirection="column" position="relative">
              <Flex justifyContent="space-between">
                <Flex width="45%" flexDirection="column">
                  <Typography mb="8px" variant="garamond-500" fontSize={[16, 18, 20]}>
                    First name*
                  </Typography>
                  <ControlledInput
                    error={errors?.firstName?.message}
                    name="firstName"
                    fontSize={[10, 12, 14]}
                    placeholder="First name"
                  />
                </Flex>
                <Flex width="45%" flexDirection="column">
                  <Typography mb="8px" variant="garamond-500" fontSize={[16, 18, 20]}>
                    Last name*
                  </Typography>
                  <ControlledInput
                    error={errors?.lastName?.message}
                    name="lastName"
                    fontSize={[10, 12, 14]}
                    placeholder="Last name"
                  />
                </Flex>
              </Flex>
              <Typography mt={10} mb="8px" variant="garamond-500" fontSize={[16, 18, 20]}>
                Email*
              </Typography>
              <ControlledInput
                error={errors?.email?.message}
                name="email"
                fontSize={[10, 12, 14]}
                placeholder="Email"
              />
              <Typography mt={10} mb="8px" variant="garamond-500" fontSize={[16, 18, 20]}>
                Phone Number
              </Typography>
              <PhoneNumberInput
                error={hasPhoneError ? VALIDATION.ERROR_MESSAGE.PHONE_NUMBER.INVALID : null}
                selectedCountry={selectedCountry}
                onCountrySelect={handleCountrySelect}
                phoneNumberValue={phoneNumberValue}
                onPhoneNumberValueChange={handlePhoneNumberChange}
              />
              <Flex>
                <BootstrapTooltip
                  title={
                    <>
                      <Typography color="inherit">Admins have the following permissions:</Typography>
                      <Typography color="inherit">
                        - to add/remove team members for easy onboarding/off-boarding
                      </Typography>
                      <Typography color="inherit">- to see all room bookings made by the team</Typography>
                      <Typography color="inherit">- to see the team’s Credit Usage history</Typography>
                      <Typography color="inherit">- to manage Company profile</Typography>
                    </>
                  }
                  placement="top">
                  <Flex mb="8px" mt={10} alignItems="center">
                    <Typography mr="6px" variant="garamond-500" fontSize={[16, 18, 20]}>
                      Role
                    </Typography>
                    <Icon SVG={Info} />
                  </Flex>
                </BootstrapTooltip>
              </Flex>
              <Flex mb="10px" alignItems="center">
                <Checkbox isBlocked={user?.id === userId} isEnabled={isAdmin} onClick={handleAdminChange} />
                <Typography color={theme.color.gray[500]} ml="10px" variant="proximaNova-400" fontSize={[12, 14]}>
                  Admin
                </Typography>
              </Flex>
              <Typography mt={22} mb="15px" variant="garamond-500" fontSize={[16, 18, 20]}>
                Permissions for Purchasing Credits
              </Typography>
              <RadioButtonGroup
                onOptionChoose={handlePurchasingPermissionChange}
                optionList={permissions.map(({ id, label }) => ({ id, label }))}
                selectedOption={purchasingPermission}
              />
              <Typography mt={22} mb="8px" variant="garamond-500" fontSize={[16, 18, 20]}>
                Room Booking Permissions
              </Typography>
              <Flex mb="10px" alignItems="center">
                <Checkbox isEnabled={isAbleToBookMeetingRoom} onClick={handleBookingPermissionChange} />
                <Typography color={theme.color.gray[500]} ml="10px" variant="proximaNova-400" fontSize={[12, 14]}>
                  Allow to book meeting rooms
                </Typography>
              </Flex>
              {adminCompanyById?.status === 'active' && user?.id !== userId && (
                <>
                  <Typography mt={22} mb="8px" variant="garamond-500" fontSize={[16, 18, 20]}>
                    Status
                  </Typography>
                  <Flex alignItems="center">
                    <Switcher isEnabled={isActive} onSwitch={handleActiveChangeState} />
                    <Typography color={theme.color.gray[500]} ml="10px" variant="proximaNova-400" fontSize={[12, 14]}>
                      Active
                    </Typography>
                  </Flex>
                </>
              )}
              <Typography color={theme.color.gray[500]} mt="20px" variant="proximaNova-400" fontSize={[12, 14]}>
                A Welcome email will be sent to newly added members
              </Typography>
              <Button
                onClick={handleSubmit(onSubmit)}
                width="fit-content"
                alignSelf="center"
                mt={30}
                px={50}
                py={12}
                variant="primary">
                {user ? 'Save changes' : 'Add Team Member'}
              </Button>
              {user && (
                <Button onClick={handleModalClose} mt={20} color={theme.color.darkGreen}>
                  Discard changes
                </Button>
              )}
            </ScrollableContainer>
          )}
        </Flex>
      </FormProvider>
      {(!!createCompanyMemberError || !!updateCompanyMemberError) && (
        <ManageCompanyMembersErrorModal
          error={{
            title: `Cannot ${updateCompanyMemberError ? 'edit' : 'add'} team member`,
            status: updateCompanyMemberError?.data.error.status || createCompanyMemberError?.data.error.status,
            message: updateCompanyMemberError?.data.error.message || createCompanyMemberError?.data.error.message,
          }}
          onModalClose={handleResetPhase}
          isOpen={!!createCompanyMemberError || !!updateCompanyMemberError}
        />
      )}
    </Modal>
  );
};

ManageCompanyMemberModal.propTypes = {
  accessId: string,
  user: exact({
    id: number,
    imageUrl: string,
    firstName: string,
    isCompanyAdmin: bool,
    lastName: string,
    isAbleToBookMeetingRoom: bool,
    email: string,
    phone: string,
    isActive: bool,
    isAbleToPayByCard: bool,
    isAbleToInvoice: bool,
  }),
  onModalClose: func.isRequired,
  isOpen: bool.isRequired,
};

ManageCompanyMemberModal.defaultProps = {
  accessId: 'my',
  user: null,
};

export default ManageCompanyMemberModal;
