import React, { useEffect, useRef, useMemo, useState } from 'react';
import { arrayOf, func, number, string, exact } from 'prop-types';
import { useRanger } from 'react-ranger';
import { dayjs } from '@utils';

import { BootstrapTooltip } from '@atoms';
import { ConfirmationModal } from '@molecules';
import { Flex, Typography, Button } from '@mixins';
import { formatTime, getDateLabel } from '@helpers/time';
import { theme } from '@styles';

import { COMMUNITY_MANAGER_ROLE, EMPLOYEE_CM_ROLE } from '@constants/common';
import {
  ScrollableContainer,
  Track,
  Handle,
  getBackground,
  getBookingBorderRadius,
  getBookingJustifyContent,
} from './styles';

const Timeline = ({
  scrollTo,
  startTime,
  endTime,
  availableTimes,
  onTimeClick,
  onTimeChange,
  timeList,
  timeFormat,
  timeZone,
  bookings,
  userRole,
}) => {
  const [selectedBooking, setSelectedBooking] = useState(null);

  const container = useRef();

  const { getTrackProps, handles } = useRanger({
    min: 0,
    max: 48,
    stepSize: 1,
    values: [startTime, endTime],
    onDrag: onTimeChange,
  });

  useEffect(() => {
    const element = document.getElementById(`timeline-${timeList[scrollTo]?.toISOString()}`);

    if (element) {
      const leftOffset = element.offsetLeft;
      container.current.scrollTo({ left: leftOffset });
    }
  }, [scrollTo, timeList]);

  const handleModalClose = () => {
    setSelectedBooking(null);
  };

  const handleBookingClick = booking => () => {
    setSelectedBooking(booking);
  };

  const timelineBlock = useMemo(
    () =>
      timeList.map((time, index) => {
        const isHalfHour = time.$m === 30;
        const currentBooking = bookings.find(
          booking => dayjs(booking.fromDateTime).isSameOrBefore(time) && dayjs(booking.toDateTime).isAfter(time),
        );

        const isBookingStart = dayjs(currentBooking?.fromDateTime).isSame(time);
        const isBookingEnd = dayjs(currentBooking?.toDateTime).isSame(timeList[index + 1]) || !timeList[index + 1];

        return (
          <Flex
            {...(availableTimes.includes(time) && { pointer: true })}
            {...(availableTimes.includes(time) && { onClick: onTimeClick(index) })}
            key={time.$d}
            id={`timeline-${time.toISOString()}`}
            position="relative"
            flexDirection="column"
            alignItems="center">
            <Flex justifyContent="center" width="100%" minHeight={20} backgroundColor={theme.color.white}>
              {!isHalfHour && (
                <Typography isHalfHour={isHalfHour} textAlign="center" variant="proximaNova-400" fontSize="9px">
                  {formatTime(time, timeFormat, '', timeZone)}
                </Typography>
              )}
            </Flex>
            <Flex
              width={[40]}
              alignItems="center"
              justifyContent={getBookingJustifyContent(isBookingStart, isBookingEnd)}
              background={getBackground(availableTimes, time, index, endTime, startTime, timeList)}
              backgroundSize="cover"
              height={isHalfHour ? '100%' : 65}>
              {!!currentBooking && (userRole === COMMUNITY_MANAGER_ROLE || userRole === EMPLOYEE_CM_ROLE) && (
                <Flex
                  border="1px solid #326164"
                  borderLeft={isBookingStart ? '1px solid #326164' : '0px'}
                  borderRight={isBookingEnd ? '1px solid #326164' : '0px'}
                  pointer
                  onClick={handleBookingClick(currentBooking)}
                  zIndex={1}
                  height="38px"
                  width={isBookingStart || isBookingEnd ? '38px' : '100%'}
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={getBookingBorderRadius(isBookingStart, isBookingEnd)}
                  background="#EFECE6">
                  {isBookingStart && (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      width="30px"
                      height="30px"
                      borderRadius="15px"
                      background={theme.color.pink}>
                      <BootstrapTooltip title={currentBooking?.company?.name} placement="top">
                        <Typography
                          p="2px"
                          overflow="hidden"
                          maxWidth="32px"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          fontSize="12px">
                          {currentBooking?.company?.name}
                        </Typography>
                      </BootstrapTooltip>
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>
            <Flex
              width="1px"
              backgroundColor={theme.color.gray[300]}
              height={isHalfHour ? '100%' : '40%'}
              right={0}
              bottom={0}
              position="absolute"
            />
          </Flex>
        );
      }),
    [scrollTo, endTime, startTime, availableTimes, timeList, timeFormat, timeZone],
  );

  const modalText = `${getDateLabel(dayjs(selectedBooking?.fromDateTime), timeZone)}\n${formatTime(
    dayjs(selectedBooking?.fromDateTime),
    timeFormat,
    '',
    timeZone,
  )} - ${formatTime(dayjs(selectedBooking?.toDateTime), timeFormat, '', timeZone)}${
    selectedBooking?.user?.firstName ? `\n${selectedBooking?.user?.firstName} ${selectedBooking?.user?.lastName}` : ''
  }`;

  return (
    <>
      {selectedBooking && (
        <ConfirmationModal
          wrapText
          title={selectedBooking?.company?.name}
          text={modalText}
          onPrimaryButtonClick={handleModalClose}
          isOpen={!!selectedBooking}
          primaryButtonText="Done"
        />
      )}
      <ScrollableContainer ref={container} pb={10} pl="6px" position="relative" flexDirection="column">
        <Track
          {...getTrackProps({
            style: {
              position: 'absolute',
            },
          })}>
          {handles.map(({ active, getHandleProps, value }) => (
            <Button
              {...getHandleProps({
                style: {
                  appearance: 'none',
                  border: 'none',
                  background: 'transparent',
                  outline: 'none',
                },
              })}>
              <Handle value={value} active={active} />
            </Button>
          ))}
        </Track>
        <Flex>{timelineBlock}</Flex>
      </ScrollableContainer>
    </>
  );
};

Timeline.defaultProps = {
  timeList: [],
  availableTimes: [],
  bookings: [],
  scrollTo: 0,
};

Timeline.propTypes = {
  userRole: string.isRequired,
  bookings: arrayOf(
    exact({
      fromDateTime: string,
      toDateTime: string,
      company: string,
    }),
  ),
  timeZone: string.isRequired,
  timeFormat: string.isRequired,
  scrollTo: number,
  onTimeClick: func.isRequired,
  startTime: number.isRequired,
  endTime: number.isRequired,
  availableTimes: arrayOf(exact),
  timeList: arrayOf(exact),
  onTimeChange: func.isRequired,
};

export default Timeline;
