import React, { useState, useEffect, useRef, useMemo } from 'react';
import { func, bool, arrayOf, number, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';

import { Icon } from '@atoms';
import { Flex, Button, Typography } from '@mixins';
import { theme } from '@styles';
import { formatTime, adjustEndTime, shouldAdjustEndTime } from '@helpers/time';

import { ReactComponent as Close } from '@assets/svgs/Close.svg';

import { HoveredBlock, ScrollableContainer } from './styles';

const TimeSelectingModal = ({
  maxBookingHours,
  isEventSpace,
  timeFormat,
  timeZone,
  onSelect,
  startTime,
  endTime,
  onModalClose,
  canBeClosed,
  availableTimes,
  times,
  scrollTo,
}) => {
  const startTimeContainer = useRef();
  const endTimeContainer = useRef();

  const [selectedStartTime, setSelectedStartTime] = useState(startTime);
  const [selectedEndTime, setSelectedEndTime] = useState(endTime);

  const {
    user: { isActivate },
  } = useSelector(store => store.userStore);

  const { currentLocation } = useSelector(store => store.locationStore);

  useEffect(() => {
    const startTimeElement = document.getElementById(`time-${times[scrollTo]}`);
    const endTimeElement = document.getElementById(`time-${times[scrollTo + 1] || times[0]}`);

    if (startTimeElement && endTimeElement) {
      const startTimeTopOffset = startTimeElement.offsetTop;
      const endTimeTopOffset = endTimeElement.offsetTop;

      startTimeContainer.current.scrollTo({ top: startTimeTopOffset });
      endTimeContainer.current.scrollTo({ top: endTimeTopOffset });
    }
  }, [scrollTo]);

  const getTimeListBlock = isStartTime =>
    times.map((time, index, arr) => {
      const isAvailable = isStartTime
        ? availableTimes.includes(time)
        : availableTimes.includes(arr[index - 1] || arr[arr.length - 2]);

      return (
        <HoveredBlock
          id={`time-${time}`}
          key={time}
          pointer
          justifyContent="center"
          alignItems="center"
          width="100%"
          {...(isAvailable && { onClick: isStartTime ? handleStartTimeSelect(time) : handleEndTimeSelect(time) })}
          px={20}
          py="7px"
          isSelected={isStartTime ? selectedStartTime === time : selectedEndTime === time}
          isAvailable={isAvailable}>
          <Typography variant="proximaNova-400" fontSize={14} color={theme.color.gray[300]}>
            {formatTime(time, timeFormat || currentLocation.timeFormat, '', timeZone || currentLocation.timeZone)}
          </Typography>
        </HoveredBlock>
      );
    });

  const handleStartTimeSelect = newStartTime => () => {
    const startTimeIndex = times.indexOf(newStartTime);

    const isTimelineAvailable = availableTimes.indexOf(times[startTimeIndex + 1]) >= 0;

    if ((!isEventSpace && !isActivate) || isTimelineAvailable) {
      setSelectedStartTime(newStartTime);

      if (shouldAdjustEndTime(selectedEndTime, newStartTime, times, isActivate, isEventSpace)) {
        setSelectedEndTime(
          adjustEndTime(selectedEndTime, newStartTime, times, availableTimes, isActivate || isEventSpace),
        );
      }
    }
  };

  const handleEndTimeSelect = newEndTime => () => {
    const endTimeIndex = times.indexOf(newEndTime) || 48;
    const startTimeIndex = times.indexOf(selectedStartTime);

    if (selectedStartTime?.$d && endTimeIndex - startTimeIndex > (isActivate || isEventSpace ? 1 : 0)) {
      if (!isEventSpace || endTimeIndex - startTimeIndex <= (isActivate || isEventSpace ? maxBookingHours * 2 : 0)) {
        setSelectedEndTime(newEndTime);
      }
    }
  };

  const handleModalClose = () => {
    setSelectedStartTime({});
    setSelectedEndTime({});
    onModalClose();
  };

  const startTimeBlock = useMemo(
    () => getTimeListBlock(true),
    [times, selectedStartTime, selectedEndTime, availableTimes],
  );

  const endTimeBlock = useMemo(
    () => getTimeListBlock(false),
    [times, selectedStartTime, selectedEndTime, availableTimes],
  );

  useEffect(() => {
    setSelectedEndTime(endTime);
    setSelectedStartTime(startTime);
  }, [startTime, endTime]);

  return (
    <Flex
      zIndex={3}
      backgroundColor={theme.color.white}
      boxShadow="0px 4px 12px rgba(0, 0, 0, 0.15)"
      flexDirection="column"
      alignItems="center"
      position="absolute"
      top={24}
      left={0}
      p={20}
      pt={canBeClosed ? 40 : 20}>
      {canBeClosed && (
        <Icon
          pointer
          onClick={handleModalClose}
          position="absolute"
          SVG={Close}
          width={11}
          height={11}
          top={17}
          right={17}
        />
      )}
      <Flex>
        <Flex mr={[30, 50]} alignItems="center" flexDirection="column">
          <Typography mb={20} variant="proximaNova-400" fontSize={16}>
            Start Time
          </Typography>
          <ScrollableContainer
            ref={startTimeContainer}
            pr="8px"
            flexDirection="column"
            maxHeight={200}
            overflow="hidden">
            {startTimeBlock}
          </ScrollableContainer>
        </Flex>
        <Flex alignItems="center" flexDirection="column">
          <Typography mb={20} variant="proximaNova-400" fontSize={16}>
            End Time
          </Typography>
          <ScrollableContainer ref={endTimeContainer} pr="8px" flexDirection="column" maxHeight={200} overflow="hidden">
            {endTimeBlock}
          </ScrollableContainer>
        </Flex>
      </Flex>
      <Button
        mt={15}
        onClick={onSelect(selectedStartTime, selectedEndTime)}
        py={12}
        width="100%"
        variant="primary"
        disabled={!selectedStartTime && selectedEndTime}>
        Select
      </Button>
    </Flex>
  );
};

TimeSelectingModal.defaultProps = {
  maxBookingHours: 3,
  isEventSpace: false,
  timeFormat: '',
  timeZone: null,
  endTime: {},
  startTime: {},
  canBeClosed: true,
  onModalClose: () => {},
  availableTimes: [],
  times: [],
  scrollTo: 0,
};

TimeSelectingModal.propTypes = {
  maxBookingHours: number,
  isEventSpace: bool,
  timeFormat: string,
  timeZone: string,
  scrollTo: number,
  availableTimes: arrayOf(shape({})),
  times: arrayOf(shape({})),
  onSelect: func.isRequired,
  endTime: shape({}),
  startTime: shape({}),
  onModalClose: func,
  canBeClosed: bool,
};

export default TimeSelectingModal;
