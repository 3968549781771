import React, { useState, useMemo, useCallback } from 'react';
import { bool, exact, number, string, instanceOf, func, arrayOf, shape } from 'prop-types';

import { Icon } from '@atoms';
import { DateSelectingModal, ListSelectingModal, RoomPhotosCarousel } from '@molecules';
import { formatTime, getDateLabel } from '@helpers/time';
import { getSuffixByNumericValue } from '@helpers/common';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { BackButton } from '@views/EditUserProfile/styles';

const formatDate = (date, timeFormat) => `${date.format('dddd')}, ${date.format('MMMM')} 
${getSuffixByNumericValue(date.$D)} at ${formatTime(date, timeFormat)}`;

const GuestHeader = ({
  timeFormat,
  isInvitationFormOpen,
  room,
  usersAmount,
  location,
  date,
  time,
  onDateSelect,
  onTimeSelect,
  onGoBack,
  times,
}) => {
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);

  const formattedTimes = useMemo(() => times.map(timeline => formatTime(timeline, timeFormat)), [timeFormat]);

  const handleDateModalState = useCallback(() => {
    setIsDateModalOpen(prevValue => {
      if (!prevValue) {
        setIsTimeModalOpen(false);
      }
      return !prevValue;
    });
  }, []);

  const handleTimeModalState = useCallback(() => {
    setIsTimeModalOpen(prevValue => {
      if (!prevValue) {
        setIsDateModalOpen(false);
      }

      return !prevValue;
    });
  }, []);

  const handleDateSelect = useCallback(newDate => {
    handleDateModalState();
    onDateSelect(newDate);
  }, []);

  const handleTimeSelect = newTime => () => {
    handleTimeModalState();
    onTimeSelect(newTime);
  };

  return usersAmount && isInvitationFormOpen ? (
    <>
      <BackButton height={12} pointer onClick={onGoBack} color={theme.color.black} mb={20} SVG={Dropdown} />
      <Typography mb={35} variant="proximaNova-600" fontSize={[14, 16, 18, 20]}>
        Add Another Guest
      </Typography>
    </>
  ) : (
    <>
      <Typography alignSelf="center" mb={10} variant="garamond-500" fontSize={[24, 28, 32, 36]}>
        {`${isInvitationFormOpen ? 'Invite' : 'Invited'} Guests`}
      </Typography>
      <Typography alignSelf="center" mb={25} variant="proximaNova-400" fontSize={[10, 12, 14, 16]}>
        {`To ${location}`}
      </Typography>
      {Object.keys(room)?.length ? (
        <Flex mb={18} width="100%" borderRadius="2px" p={12} border="1px solid #EFECE6">
          <Flex mr={20} minHeight={80} width={130} height="100%">
            <RoomPhotosCarousel isSmall photos={room.roomPhotos} />
          </Flex>
          <Flex justifyContent="center" flexDirection="column">
            <Typography mb="5px" variant="proximaNova-400" fontSize={[10, 12, 14, 16]}>
              {formatDate(room.startDate, timeFormat)}
            </Typography>
            <Typography mb="5px" variant="proximaNova-400" fontSize={[10, 12, 14, 16]} color={theme.color.gray[300]}>
              {`${room.number} · ${getSuffixByNumericValue(room.floor)} floor`}
            </Typography>
            <Typography variant="proximaNova-400" fontSize={[10, 12, 14, 16]} color={theme.color.gray[300]}>
              {room.title}
            </Typography>
          </Flex>
        </Flex>
      ) : (
        <Flex mb={30} width="100%" justifyContent="space-between">
          <Flex width="45%" position="relative">
            <Flex flexDirection="column" width="100%">
              <Typography mb={15} variant="garamond-500" fontSize={[18, 20, 22, 24]}>
                Date*
              </Typography>
              <Flex
                justifyContent="space-between"
                width="100%"
                pointer
                onClick={handleDateModalState}
                borderBottom={`1px solid ${theme.color.gray[500]}`}
                alignItems="center">
                <Typography variant="proximaNova-400" fontSize={[10, 13, 16]} mb="5px">
                  {getDateLabel(date)}
                </Typography>
                <Icon SVG={Dropdown} height={[6, 6, 10, 14]} color={theme.color.black} />
              </Flex>
            </Flex>
            {isDateModalOpen && (
              <DateSelectingModal
                canBeClosed={false}
                hasSelectingButton={false}
                onSelect={handleDateSelect}
                onModalClose={handleDateModalState}
                calendarDate={date.$d}
                type="month"
                topPosition={82}
              />
            )}
          </Flex>
          <Flex width="45%" position="relative">
            <Flex flexDirection="column" width="100%">
              <Typography mb={15} variant="garamond-500" fontSize={[18, 20, 22, 24]}>
                Time
              </Typography>
              <Flex
                justifyContent="space-between"
                width="100%"
                pointer
                onClick={handleTimeModalState}
                borderBottom={`1px solid ${theme.color.gray[500]}`}
                alignItems="center">
                <Typography
                  variant="proximaNova-400"
                  color={time ? theme.color.gray[500] : theme.color.gray[200]}
                  fontSize={[10, 13, 16]}
                  mb="5px">
                  {time || 'Select'}
                </Typography>
                <Icon SVG={Dropdown} height={[6, 6, 10, 14]} color={theme.color.black} />
              </Flex>
            </Flex>
            {isTimeModalOpen && (
              <ListSelectingModal
                scrollTo={formatTime(times[20], timeFormat)}
                canBeClosed={false}
                hasSelectingButton={false}
                items={formattedTimes}
                selectedItem={time}
                onSelect={handleTimeSelect}
                onModalClose={handleTimeModalState}
                topPosition={82}
              />
            )}
          </Flex>
        </Flex>
      )}
    </>
  );
};

GuestHeader.defaultProps = {
  room: {},
  location: '',
  timeFormat: '24h',
  times: [],
};

GuestHeader.propTypes = {
  times: arrayOf(shape({})),
  timeFormat: string,
  date: shape({}).isRequired,
  time: string.isRequired,
  onDateSelect: func.isRequired,
  onTimeSelect: func.isRequired,
  isInvitationFormOpen: bool.isRequired,
  room: exact({
    id: string,
    roomPhotos: arrayOf(string),
    startDate: instanceOf(Date),
    number: string,
    floor: number,
    title: string,
  }),
  usersAmount: number.isRequired,
  location: string,
  onGoBack: func.isRequired,
};

export default GuestHeader;
