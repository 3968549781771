import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Avatar, BootstrapTooltip, Icon } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { routes } from '@constants';
import { getSuffixByNumericValue, getCloudinaryImageLinkBySize, getCurrency } from '@helpers/common';
import { formatTime } from '@helpers/time';
import { dayjs } from '@utils';
import { theme } from '@styles';

import Gif from '@assets/gifs/no-bookings.gif';
import RoomImage from '@assets/images/RoomImage.png';

import { ReactComponent as ExclamationMark } from '@assets/svgs/ExclamationMark.svg';
import { COMPANY_ADMIN_ROLE } from '@constants/common';
import { Container } from './styles';

const BookingList = () => {
  const history = useHistory();

  const { bookingHistory } = useSelector(store => store.bookingStore);

  const { currentLocation } = useSelector(store => store.locationStore);

  const { user } = useSelector(store => store.userStore);

  const handleBookAgainClick = id => () => {
    history.push(`${routes.rooms}/${id}`);
  };

  const handleManageBookingClick = id => () => {
    history.push(`${routes.bookedRooms}/${id}`);
  };

  const handleUserClick = id => () => {
    history.push({
      pathname: `${routes.userProfile}/${id}`,
      state: {
        from: user.id !== id ? routes.bookingHistory : null,
      },
    });
  };

  const getCanceledBookingLabel = refundStatus => {
    if (refundStatus === 'no_refund') {
      return `Booking was cancelled less than ${user.isActivate ? '24 hrs' : '1 hr'} in advance and hence,
         wasn't eligible for a refund. :(`;
    }
    if (refundStatus === 'refund_to_expired') {
      return 'Credits not refunded';
    }
    return 'Credits refunded';
  };

  const roomsBlock = useMemo(
    () =>
      bookingHistory.map(
        ({
          id,
          credits,
          money,
          fromDateTime,
          toDateTime,
          paymentType,
          status,
          refundStatus,
          meetingRoom: {
            id: roomId,
            image,
            roomNumber,
            floor,
            location: { title, currency, timeFormat, timeZone },
          },
          user: { id: userId, imageUrl, firstName, lastName, jobTitle },
        }) => (
          <Flex minHeight="75px" border="1px solid #EFECE6" borderRadius="2px" key={id}>
            <Flex
              width="100%"
              maxWidth="140px"
              height="auto"
              background={`url(${getCloudinaryImageLinkBySize(image, 140) || RoomImage}) center`}
              backgroundSize="cover"
            />
            <Flex
              width="100%"
              alignItems="center"
              display="grid"
              gridTemplateColumns="1.5fr 1fr 1fr 1.5fr 2.5fr auto"
              px={30}>
              <Flex flexDirection="column" flexGrow={1} pr={10}>
                <Typography variant="proximaNova-600" fontSize={16}>
                  {`${roomNumber} · ${getSuffixByNumericValue(floor)} floor`}
                </Typography>
                <Typography variant="proximaNova-400" fontSize={14} color={theme.color.gray[300]}>
                  {title}
                </Typography>
              </Flex>
              <Flex flexDirection="column" flexGrow={1} pr={10}>
                <Typography variant="proximaNova-400" fontSize={14} color={theme.color.gray[300]}>
                  {`${dayjs(fromDateTime).tz(timeZone).format('MMMM')} ${dayjs(fromDateTime).tz(timeZone).date()}, 
          ${dayjs(fromDateTime).tz(timeZone).format('YYYY')}`}
                </Typography>
                <Typography variant="proximaNova-400" fontSize={14} color={theme.color.gray[300]}>
                  {`${formatTime(fromDateTime, timeFormat, '', timeZone)}-${formatTime(
                    toDateTime,
                    timeFormat,
                    '',
                    timeZone,
                  )}`}
                </Typography>
              </Flex>
              <Typography variant="proximaNova-400" fontSize={14} color={theme.color.gray[300]} flexGrow={1} pr={10}>
                {paymentType === 'money'
                  ? getCurrency(currency, money)
                  : `${credits} ${credits === 1 ? 'credit' : 'credits'}`}
              </Typography>
              <Flex
                width="fit-content"
                pointer
                onClick={handleUserClick(userId)}
                alignItems="center"
                flexGrow={1}
                pr={10}>
                <Avatar
                  width={[32, 42]}
                  height={[32, 42]}
                  imageUrl={imageUrl}
                  firstName={firstName}
                  lastName={lastName}
                  fontSize={['10px', '10px', '12px', '14px']}
                />
                <Flex ml={['8px', 10]} flexDirection="column">
                  <Typography fontSize={[12, 12, 14, 16]} variant="proximaNova-600" wordBreak="break-word">
                    {`${firstName} ${lastName}`}
                  </Typography>
                  <Typography variant="proximaNova-400" color={theme.color.gray[300]} fontSize={[10, 10, 12, 14]}>
                    {jobTitle}
                  </Typography>
                </Flex>
              </Flex>
              <Flex justifyContent="start">
                {status === 'canceled' && (
                  <BootstrapTooltip
                    title={
                      <>
                        <Typography color="inherit">Refunds are possible when:</Typography>
                        <Typography color="inherit">
                          - Room is cancelled at least 1 hr before the meeting start time
                        </Typography>
                        <Typography color="inherit">- Credit package used for the booking is still active</Typography>
                      </>
                    }
                    placement="top">
                    <Flex>
                      <Icon height={16} minWidth={16} mr="8px" mt="2px" SVG={ExclamationMark} />
                      <Typography variant="proximaNova-400" fontSize={[10, 10, 12, 14]}>
                        {getCanceledBookingLabel(refundStatus)}
                      </Typography>
                    </Flex>
                  </BootstrapTooltip>
                )}
              </Flex>
              <Flex minWidth="110px" justifyContent="end">
                {user.isAbleToBookMeetingRoom &&
                  (userId === user.id || dayjs().tz(timeZone).isSameOrAfter(fromDateTime) || status === 'canceled') &&
                  (status === 'canceled' ? (
                    <Typography variant="proximaNova-600" fontSize={[10, 12, 14, 16]} color={theme.color.red}>
                      Cancelled
                    </Typography>
                  ) : (
                    <Button
                      textAlign="end"
                      color={theme.color.darkGreen}
                      py={[15, 18, 21, 24]}
                      onClick={
                        dayjs().isBefore(fromDateTime) && status !== 'canceled'
                          ? handleManageBookingClick(id)
                          : handleBookAgainClick(roomId)
                      }
                      pl={30}>
                      {dayjs().isBefore(fromDateTime) ? 'Manage' : 'Book again'}
                    </Button>
                  ))}
              </Flex>
            </Flex>
          </Flex>
        ),
      ),
    [bookingHistory, currentLocation.timeZone],
  );

  return bookingHistory.length ? (
    <>
      {user.role === COMPANY_ADMIN_ROLE && (
        <Typography color={theme.color.gray[500]} variant="garamond-500" fontSize={[22, 26, 30]} mb={25}>
          Room Bookings
        </Typography>
      )}
      <Container>{roomsBlock}</Container>
    </>
  ) : (
    <Flex width="100%" alignItems="center" flexDirection="column">
      <Flex as="img" mb={25} width={[200, 300]} height={[165, 250]} src={Gif} />
      <Typography mb={10} variant="garamond-500" fontSize={[26, 36, 46]}>
        Nothing to show..
      </Typography>
      <Typography variant="proximaNova-400" fontSize={[14, 16, 18]}>
        Let’s get some rooms booked in the house!
      </Typography>
    </Flex>
  );
};

export default BookingList;
